import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputAdornment
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import PageLayout from '../PageLayout';
import api from '../../utils/api';

function PurchaseOrderForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!!id);
  const [error, setError] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [formData, setFormData] = useState({
    supplier_id: '',
    expected_delivery: '',
    notes: '',
    items: []
  });
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [suppliersResponse, materialsResponse] = await Promise.all([
          api.get('/materials/suppliers'),
          api.get('/materials')
        ]);
        setSuppliers(suppliersResponse.data);
        setMaterials(materialsResponse.data);

        if (id) {
          const poResponse = await api.get(`/materials/purchase-orders/${id}`);
          const po = poResponse.data;
          setFormData({
            supplier_id: po.supplier_id,
            expected_delivery: po.expected_delivery ? new Date(po.expected_delivery).toISOString().split('T')[0] : '',
            notes: po.notes || '',
            items: po.items.map(item => ({
              material_id: item.material_id,
              quantity: item.quantity,
              unit_cost: item.unit_cost || materials.find(m => m.id === item.material_id)?.cost || 0
            }))
          });
          setSelectedSupplier(suppliersResponse.data.find(s => s.id === po.supplier_id));
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [id]);

  const handleAddItem = () => {
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, {
        material_id: '',
        quantity: 1,
        unit_cost: 0
      }]
    }));
  };

  const handleRemoveItem = (index) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === index) {
          if (field === 'material_id') {
            const material = materials.find(m => m.id === value);
            return {
              ...item,
              [field]: value,
              unit_cost: material?.cost || 0
            };
          }
          return {
            ...item,
            [field]: field === 'unit_cost' ? parseFloat(value) || 0 :
              field === 'quantity' ? parseInt(value) || 0 : value
          };
        }
        return item;
      })
    }));
  };

  const calculateTotal = () => {
    return formData.items.reduce((sum, item) => {
      return sum + (item.quantity * item.unit_cost);
    }, 0);
  };

  const calculateItemTotal = (item) => {
    return item.quantity * item.unit_cost;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submitData = {
        ...formData,
        supplier_id: selectedSupplier?.id,
      };

      if (id) {
        await api.put(`/materials/purchase-orders/${id}`, submitData);
      } else {
        await api.post('/materials/purchase-orders', submitData);
      }

      navigate('/purchase-orders');
    } catch (error) {
      console.error('Error saving purchase order:', error);
      setError('Failed to save purchase order. Please try again.');
    }
  };

  if (loading) return <PageLayout><Typography>Loading...</Typography></PageLayout>;

  return (
    <PageLayout>
      <Typography variant="h4" gutterBottom>
        {id ? 'Edit Purchase Order' : 'Create Purchase Order'}
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              options={suppliers}
              getOptionLabel={(option) => option.name}
              value={selectedSupplier}
              onChange={(event, newValue) => {
                setSelectedSupplier(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Expected Delivery Date"
              type="date"
              value={formData.expected_delivery}
              onChange={(e) => setFormData(prev => ({ ...prev, expected_delivery: e.target.value }))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              multiline
              rows={3}
              value={formData.notes}
              onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddItem}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer component={Paper} variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Material</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Autocomplete
                          options={materials}
                          getOptionLabel={(option) => `${option.name} (${option.sku || 'No SKU'})`}
                          value={materials.find(m => m.id === item.material_id) || null}
                          onChange={(event, newValue) => {
                            handleItemChange(index, 'material_id', newValue?.id);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          size="small"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          size="small"
                          value={item.unit_cost}
                          onChange={(e) => handleItemChange(index, 'unit_cost', e.target.value)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: { min: 0, step: 0.01 }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        ${calculateItemTotal(item).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveItem(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {formData.items.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No items added. Click "Add Item" to begin.
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <Typography variant="subtitle1" fontWeight="bold">
                        Total:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        ${calculateTotal().toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/purchase-orders')}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.items.length === 0 || !selectedSupplier}
              >
                {id ? 'Update' : 'Create'} Purchase Order
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default PurchaseOrderForm;
