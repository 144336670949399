// src/components/products/ProductTable.js
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { formatDate } from './utils';
import {
  ProductImage,
  ProductBarcodeStatus,
  ProductPrice,
  ProductActions
} from './components';

const SortableTableCell = ({ label, field, currentSort, onSort, children }) => (
  <TableCell
    onClick={() => onSort(field)}
    sx={{
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'action.hover'
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {children || label}
      {currentSort.field === field && (
        <IconButton size="small" sx={{ p: 0 }}>
          {currentSort.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      )}
    </Box>
  </TableCell>
);

const ProductTable = ({
  products,
  productImages,
  selectedProducts,
  onProductSelect,
  onProductClick,
  onDeleteProduct,
  sortField,
  sortDirection,
  onSort
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      onProductSelect(products.map(p => p.id));
    } else {
      onProductSelect([]);
    }
  };

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'primary.main' }}>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                checked={selectedProducts.length === products.length}
                onChange={handleSelectAll}
              />
            </TableCell>
            {!isMobile && <TableCell style={{ width: '60px' }}></TableCell>}
            <SortableTableCell
              label="Name"
              field="product_name"
              currentSort={{ field: sortField, direction: sortDirection }}
              onSort={onSort}
            />
            {!isMobile && (
              <SortableTableCell
                label="Category"
                field="category"
                currentSort={{ field: sortField, direction: sortDirection }}
                onSort={onSort}
              />
            )}
            {!isMobile && (
              <SortableTableCell
                label="Subcategory"
                field="subcategory"
                currentSort={{ field: sortField, direction: sortDirection }}
                onSort={onSort}
              />
            )}
            <SortableTableCell
              label="Price"
              field="price"
              currentSort={{ field: sortField, direction: sortDirection }}
              onSort={onSort}
            />
            <TableCell>Barcodes</TableCell>
            {!isMobile && (
              <SortableTableCell
                label="Created"
                field="created_at"
                currentSort={{ field: sortField, direction: sortDirection }}
                onSort={onSort}
              />
            )}
            {!isMobile && (
              <SortableTableCell
                label="Updated"
                field="updated_at"
                currentSort={{ field: sortField, direction: sortDirection }}
                onSort={onSort}
              />
            )}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              hover
              key={product.id}
              onClick={() => onProductClick(product.id)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => onProductSelect([product.id])}
                />
              </TableCell>
              {!isMobile && (
                <TableCell style={{ width: '60px', height: '60px', padding: 1 }}>
                  <ProductImage
                    product={product}
                    imageData={productImages[product.id]}
                    height={50}
                    width={50}
                    showPlaceholder={false}
                  />
                </TableCell>
              )}
              <TableCell>
                <Tooltip title={`SKU: ${product.sku}`} arrow>
                  <Typography variant="body2">{product.product_name}</Typography>
                </Tooltip>
              </TableCell>
              {!isMobile && <TableCell>{product.category || 'N/A'}</TableCell>}
              {!isMobile && <TableCell>{product.subcategory || 'N/A'}</TableCell>}
              <TableCell>
                <ProductPrice prices={product.prices} />
              </TableCell>
              <TableCell>
                <ProductBarcodeStatus product={product} />
              </TableCell>
              {!isMobile && <TableCell>{formatDate(product.created_at)}</TableCell>}
              {!isMobile && <TableCell>{formatDate(product.updated_at)}</TableCell>}
              <TableCell>
                <ProductActions
                  onEdit={() => onProductClick(product.id)}
                  onDelete={() => onDeleteProduct(product)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
