import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import api from '../utils/api';

const SalesUploadForm = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setUploadStatus({ severity: 'error', message: 'Please select a file to upload.' });
      return;
    }

    setUploading(true);
    setUploadStatus(null);

    const formData = new FormData();
    formData.append('salesFile', file);

    try {
      const response = await api.post('/sales/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUploadStatus({ severity: 'success', message: `${response.data.processedSales} sales records processed successfully. ${response.data.duplicates} duplicates skipped.` });
    } catch (error) {
      setUploadStatus({ severity: 'error', message: `Error uploading file: ${error.response?.data?.message || error.message}` });
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Upload Sales Spreadsheet
      </Typography>
      <input
        accept=".csv,.xlsx,.xls"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" startIcon={<UploadFileIcon />}>
          Select File
        </Button>
      </label>
      {file && <Typography sx={{ mt: 2 }}>{file.name}</Typography>}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        disabled={!file || uploading}
      >
        {uploading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
      {uploadStatus && (
        <Alert severity={uploadStatus.severity} sx={{ mt: 2 }}>
          {uploadStatus.message}
        </Alert>
      )}
    </Box>
  );
};

export default SalesUploadForm;
