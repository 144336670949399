// src/components/products/components/ProductImage.js
import React, { useState } from 'react';
import {
  Box,
  CardMedia,
  Typography,
  CircularProgress
} from '@mui/material';
import { getProductDisplayImage } from '../utils';

export const ProductImage = ({
  product,
  imageData,
  height = 200,
  width = '100%',
  showPlaceholder = true
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const imageUrl = getProductDisplayImage(product, { [product.id]: imageData });

  if (!imageUrl && !showPlaceholder) {
    return null;
  }

  return (
    <Box sx={{
      position: 'relative',
      height,
      width,
      backgroundColor: 'grey.100',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      overflow: 'hidden'
    }}>
      {isLoading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1
        }}>
          <CircularProgress size={24} />
        </Box>
      )}
      <CardMedia
        component="img"
        height={height}
        width={width}
        image={imageUrl || '/api/placeholder/200/200'}
        alt={product.product_name}
        sx={{
          objectFit: 'contain',
          p: 1,
          transition: 'opacity 0.3s ease-in-out',
          opacity: isLoading ? 0 : 1,
          filter: loadError ? 'grayscale(100%)' : 'none'
        }}
        loading="lazy"
        onLoad={() => {
          setIsLoading(false);
          setLoadError(false);
        }}
        onError={() => {
          setIsLoading(false);
          setLoadError(true);
        }}
      />
      {loadError && (
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: 'text.secondary'
        }}>
          <Typography variant="caption">
            Unable to load image
          </Typography>
        </Box>
      )}
    </Box>
  );
};
