// src/components/Lists/ListsOverview.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { listService } from '../../services';
import { handleError } from '../../utils/errorHandler';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { LoadingSpinner, ErrorMessage } from '../common';
import PageLayout from '../PageLayout';
import { ListToolbar } from './components';
import { ListFilters } from './components';
import { ListTable } from './components';

function ListsOverview() {
  // View State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newListName, setNewListName] = useState('');

  // Data State
  const [lists, setLists] = useState([]);
  const [filteredLists, setFilteredLists] = useState([]);

  // Filter State
  const [filterState, setFilterState] = useLocalStorage(LOCAL_STORAGE_KEYS.LIST_FILTER_STATE, {
    searchTerm: '',
    sortField: 'name',
    sortDirection: 'asc'
  });

  const handleCreateList = async () => {
    if (!newListName.trim()) return;

    try {
      const newList = await listService.create({ name: newListName });
      setLists(prevLists => [...prevLists, newList]);
      setNewListName('');
      setCreateDialogOpen(false);
    } catch (error) {
      setError(handleError(error));
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilterState(prev => ({ ...prev, ...newFilters }));
  };

  const handleDeleteList = async (listId) => {
    try {
      await listService.delete(listId);
      setLists(lists.filter(list => list.id !== listId));
    } catch (error) {
      setError(handleError(error));
    }
  };

  // Fetch Lists
  useEffect(() => {
    const fetchLists = async () => {
      try {
        setLoading(true);
        const data = await listService.getAll();
        setLists(data);
      } catch (error) {
        setError(handleError(error));
      } finally {
        setLoading(false);
      }
    };

    fetchLists();
  }, []);

  // Filter and Sort Lists
  useEffect(() => {
    let filtered = [...lists];

    // Filter by search term
    if (filterState.searchTerm) {
      const searchLower = filterState.searchTerm.toLowerCase();
      filtered = filtered.filter(list =>
        list.name.toLowerCase().includes(searchLower)
      );
    }

    // Sort lists
    filtered.sort((a, b) => {
      const aValue = (a[filterState.sortField] || '').toString().toLowerCase();
      const bValue = (b[filterState.sortField] || '').toString().toLowerCase();
      return filterState.sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setFilteredLists(filtered);
  }, [lists, filterState]);

  if (loading) return <PageLayout><LoadingSpinner fullHeight /></PageLayout>;
  if (error) return <PageLayout><ErrorMessage message={error} /></PageLayout>;

  return (
    <PageLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <ListToolbar
          onCreateList={() => setCreateDialogOpen(true)}
          totalLists={filteredLists.length}
        />

        <ListFilters
          filterState={filterState}
          onFilterChange={handleFilterChange}
        />

        <ListTable
          lists={filteredLists}
          onDelete={handleDeleteList}
          sortField={filterState.sortField}
          sortDirection={filterState.sortDirection}
          onSort={(field) => handleFilterChange({
            sortField: field,
            sortDirection: filterState.sortField === field ?
              (filterState.sortDirection === 'asc' ? 'desc' : 'asc') :
              'asc'
          })}
        />

        <Dialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Create New List</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="List Name"
              type="text"
              fullWidth
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleCreateList}
              color="primary"
              variant="contained"
              disabled={!newListName.trim()}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </PageLayout>
  );
}

export default ListsOverview;
