import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Chip,
  Menu,
  MenuItem,
  Grid,
  TextField
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { LoadingSpinner, ErrorMessage, FilterBar } from '../common';
import PageLayout from '../PageLayout';
import api from '../../utils/api';
import { formatDate } from '../../utils/dateUtils';

const STATUS_COLORS = {
  'draft': 'default',
  'pending': 'primary',
  'ordered': 'info',
  'received': 'success',
  'cancelled': 'error'
};

function PurchaseOrdersList() {
  const navigate = useNavigate();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPO, setSelectedPO] = useState(null);
  const [filterState, setFilterState] = useState({
    searchTerm: '',
    status: 'all',
    sortField: 'created_at',
    sortDirection: 'desc'
  });

  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  const fetchPurchaseOrders = async () => {
    try {
      setLoading(true);
      const response = await api.get('/materials/purchase-orders');
      setPurchaseOrders(response.data);
    } catch (error) {
      console.error('Error fetching purchase orders:', error);
      setError('Failed to load purchase orders. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await api.put(`/materials/purchase-orders/${orderId}/status`, {
        status: newStatus
      });
      fetchPurchaseOrders();
    } catch (error) {
      console.error('Error updating purchase order status:', error);
      setError('Failed to update status. Please try again.');
    }
    setAnchorEl(null);
  };

  const handleDelete = async (orderId) => {
    if (window.confirm('Are you sure you want to delete this purchase order?')) {
      try {
        await api.delete(`/materials/purchase-orders/${orderId}`);
        fetchPurchaseOrders();
      } catch (error) {
        console.error('Error deleting purchase order:', error);
        setError('Failed to delete purchase order. Please try again.');
      }
    }
  };

  const handleMenuClick = (event, po) => {
    setAnchorEl(event.currentTarget);
    setSelectedPO(po);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPO(null);
  };

  const filteredOrders = purchaseOrders
    .filter(po => {
      if (filterState.status !== 'all' && po.status !== filterState.status) {
        return false;
      }
      const searchLower = filterState.searchTerm.toLowerCase();
      return (
        po.supplier?.name.toLowerCase().includes(searchLower) ||
        po.po_number?.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      const aValue = a[filterState.sortField];
      const bValue = b[filterState.sortField];
      const direction = filterState.sortDirection === 'asc' ? 1 : -1;
      return direction * (aValue > bValue ? 1 : -1);
    });

  const calculateTotal = (items) => {
    return items.reduce((sum, item) => sum + (item.quantity * item.unit_cost), 0);
  };

  if (loading) return <PageLayout><LoadingSpinner /></PageLayout>;
  if (error) return <PageLayout><ErrorMessage message={error} /></PageLayout>;

  return (
    <PageLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">Purchase Orders</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/purchase-orders/add')}
          >
            Create Purchase Order
          </Button>
        </Box>

        <FilterBar>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Search"
                value={filterState.searchTerm}
                onChange={(e) => setFilterState(prev => ({ ...prev, searchTerm: e.target.value }))}
                placeholder="Search by supplier or PO number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Status"
                value={filterState.status}
                onChange={(e) => setFilterState(prev => ({ ...prev, status: e.target.value }))}
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="ordered">Ordered</MenuItem>
                <MenuItem value="received">Received</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </FilterBar>

        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>PO Number</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Expected Delivery</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((po) => (
                <TableRow key={po.id}>
                  <TableCell>{po.po_number}</TableCell>
                  <TableCell>{po.supplier?.name}</TableCell>
                  <TableCell>{formatDate(po.created_at)}</TableCell>
                  <TableCell>{formatDate(po.expected_delivery)}</TableCell>
                  <TableCell>${calculateTotal(po.items).toFixed(2)}</TableCell>
                  <TableCell>
                    <Chip
                      label={po.status.toUpperCase()}
                      color={STATUS_COLORS[po.status]}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => navigate(`/purchase-orders/${po.id}/edit`)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => handleMenuClick(e, po)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {selectedPO && selectedPO.status !== 'cancelled' && (
            <MenuItem onClick={() => handleStatusChange(selectedPO.id, 'cancelled')}>
              Cancel Order
            </MenuItem>
          )}
          {selectedPO && selectedPO.status === 'pending' && (
            <MenuItem onClick={() => handleStatusChange(selectedPO.id, 'ordered')}>
              Mark as Ordered
            </MenuItem>
          )}
          {selectedPO && selectedPO.status === 'ordered' && (
            <MenuItem onClick={() => handleStatusChange(selectedPO.id, 'received')}>
              Mark as Received
            </MenuItem>
          )}
          {selectedPO && selectedPO.status === 'draft' && (
            <MenuItem onClick={() => handleStatusChange(selectedPO.id, 'pending')}>
              Submit for Approval
            </MenuItem>
          )}
          <MenuItem onClick={() => handleDelete(selectedPO.id)} sx={{ color: 'error.main' }}>
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </PageLayout>
  );
}

export default PurchaseOrdersList;
