// src/components/common/ErrorMessage.js
import React from 'react';
import { Alert, Box } from '@mui/material';

export const ErrorMessage = ({ message }) => (
  <Box sx={{ p: 2 }}>
    <Alert severity="error" variant="filled">
      {message}
    </Alert>
  </Box>
);
