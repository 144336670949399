import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

export const FilterBar = ({ title = 'Filters', children }) => (
  <Card elevation={3} sx={{ mb: 4 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {children}
    </CardContent>
  </Card>
);
