import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon
} from '@mui/icons-material';
import { LoadingSpinner, ErrorMessage, FilterBar } from '../common';
import PageLayout from '../PageLayout';
import api from '../../utils/api';
import { formatDate } from '../../utils/dateUtils';
import DesignForm from './DesignForm';

const DesignsOverview = () => {
  const navigate = useNavigate();
  const [designs, setDesigns] = useState([]);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newDesign, setNewDesign] = useState({
    name: '',
    description: '',
    author: '',
    purchase_source: '',
    purchase_price: '',
    license_info: ''
  });

  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    try {
      setLoading(true);
      const response = await api.get('/designs');
      setDesigns(response.data);
      setFilteredDesigns(response.data);
    } catch (error) {
      console.error('Error fetching designs:', error);
      setError('Failed to load designs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderImage = (files) => {
    const imageFile = files.find(file => ['png', 'jpg', 'jpeg', 'svg'].some(ext => file.file_type.includes(ext)));
    if (!imageFile) return null;
    return (
      <CardMedia
        component="img"
        image={imageFile.s3_key}  // Use s3_key if the file URL is stored there
        alt={imageFile.original_filename}
        style={{ height: '100px', width: '100px', objectFit: 'cover' }}
      />
    );
  };

  useEffect(() => {
    const filtered = designs.filter(design =>
      design.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (design.author && design.author.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredDesigns(filtered);
  }, [designs, searchTerm]);

  const handleDeleteDesign = async (designId) => {
    try {
      await api.delete(`/designs/${designId}`);
      setDesigns(designs.filter(design => design.id !== designId));
    } catch (error) {
      console.error('Error deleting design:', error);
      setError('Failed to delete design. Please try again.');
    }
  };

  const handleDesignClick = (designId) => {
    navigate(`/designs/${designId}/edit`);
  };


  if (loading) return <PageLayout><LoadingSpinner /></PageLayout>;
  if (error) return <PageLayout><ErrorMessage message={error} /></PageLayout>;

  return (
    <PageLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Filters */}
        <FilterBar>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name or author"
              />
            </Grid>
          </Grid>
        </FilterBar>

        {/* Header and Create Button */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/designs/add')}
          >
            Create New Design
          </Button>
        </Box>

        {/* Designs Table */}
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'primary.main' }}>
                <TableCell>Name</TableCell>
                <TableCell>Designer</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDesigns.map((design) => (
                <TableRow
                  hover
                  key={design.id}
                  onClick={() => handleDesignClick(design.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{design.name}</TableCell>
                  <TableCell>{design.author || 'N/A'}</TableCell>
                  <TableCell>{design.source_type}</TableCell>
                  <TableCell>{formatDate(design.created_at)}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleDesignClick(design.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteDesign(design.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </PageLayout>
  );
};

export default DesignsOverview;
