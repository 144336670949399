// src/components/products/ProductActions.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';

const ProductActions = ({
  selectedCount,
  onCreateNew,
  lists,
  onAddToList
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedList, setSelectedList] = useState('');

  const handleAddToList = () => {
    onAddToList(selectedList);
    setDialogOpen(false);
    setSelectedList('');
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2
    }}>
      <Box>
        <Button
          component={Link}
          to="/products/add"
          variant="contained"
          color="primary"
          onClick={onCreateNew}
          sx={{ mr: 2 }}
        >
          Create New Product
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setDialogOpen(true)}
          disabled={selectedCount === 0}
        >
          Add Selected to List ({selectedCount})
        </Button>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add to List</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Select a list to add the {selectedCount} selected product{selectedCount !== 1 ? 's' : ''} to:
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel>List</InputLabel>
            <Select
              value={selectedList}
              onChange={(e) => setSelectedList(e.target.value)}
              label="List"
            >
              {lists.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name} ({list.itemCount} items)
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleAddToList}
            variant="contained"
            color="primary"
            disabled={!selectedList}
          >
            Add to List
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductActions;
