// src/components/products/ProductGrid.js
import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  IconButton,
  Checkbox,
  Tooltip
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import {
  formatPriceRange,
  formatDate,
  getProductDisplayImage
} from './utils';
import { ProductImage } from './components';

const ProductCard = ({
  product,
  imageData,
  isSelected,
  onSelect,
  onClick,
  onEdit,
  onDelete
}) => (
  <Card
    elevation={3}
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        boxShadow: 6,
      }
    }}
  >
    <CardActionArea onClick={() => onClick(product.id)}>
      <ProductImage product={product} imageData={imageData} />
      <CardContent>
        <Tooltip
          title={
            <Box sx={{ p: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                {product.product_name}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4} sx={{ color: 'text.secondary' }}>SKU:</Grid>
                <Grid item xs={8}>{product.sku}</Grid>
                <Grid item xs={4} sx={{ color: 'text.secondary' }}>Category:</Grid>
                <Grid item xs={8}>{product.category || 'N/A'}</Grid>
                <Grid item xs={4} sx={{ color: 'text.secondary' }}>Subcategory:</Grid>
                <Grid item xs={8}>{product.subcategory || 'N/A'}</Grid>
                <Grid item xs={4} sx={{ color: 'text.secondary' }}>Created:</Grid>
                <Grid item xs={8}>{formatDate(product.created_at)}</Grid>
                <Grid item xs={4} sx={{ color: 'text.secondary' }}>Updated:</Grid>
                <Grid item xs={8}>{formatDate(product.updated_at)}</Grid>
              </Grid>
            </Box>
          }
          arrow
          placement="top"
          enterDelay={500}
          leaveDelay={200}
        >
          <Box>
            <Typography variant="h6" component="div" noWrap>
              {product.product_name}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              SKU: {product.sku}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Category: {product.category || 'N/A'}
            </Typography>
            <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
              {formatPriceRange(product.prices)}
            </Typography>
          </Box>
        </Tooltip>
      </CardContent>
    </CardActionArea>
    <Box sx={{ p: 1, mt: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Checkbox
          checked={isSelected}
          onChange={() => onSelect(product.id)}
          onClick={(e) => e.stopPropagation()}
        />
        <Box>
          <IconButton
            size="small"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(product.id);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(product);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  </Card>
);

const ProductGrid = ({
  products,
  productImages,
  selectedProducts,
  onProductSelect,
  onProductClick,
  onEditProduct,
  onDeleteProduct
}) => {
  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
          <ProductCard
            product={product}
            imageData={productImages[product.id]}
            isSelected={selectedProducts.includes(product.id)}
            onSelect={onProductSelect}
            onClick={onProductClick}
            onEdit={onEditProduct}
            onDelete={onDeleteProduct}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductGrid;
