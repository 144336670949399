// src/components/lists/components/ListActions.js
import React from 'react';
import { IconButton, Box } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

export const ListActions = ({ onEdit, onDelete, size = 'small' }) => (
  <Box sx={{ display: 'flex', gap: 1 }}>
    <IconButton
      size={size}
      color="primary"
      onClick={onEdit}
    >
      <EditIcon />
    </IconButton>
    <IconButton
      size={size}
      color="error"
      onClick={onDelete}
    >
      <DeleteIcon />
    </IconButton>
  </Box>
);
