// src/components/products/utils.js
import { SORT_OPTIONS, SORT_DIRECTIONS } from '../../constants';

export const formatPrice = (price) => {
  if (!price && price !== 0) return 'N/A';
  return `$${Number(price).toFixed(2)}`;
};

export const formatPriceRange = (prices) => {
  if (!prices?.length) return 'N/A';

  const minPrice = Math.min(...prices.map(p => p.price));
  const maxPrice = Math.max(...prices.map(p => p.price));

  if (minPrice === maxPrice) {
    return formatPrice(minPrice);
  }
  return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
};

export const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const hasBarcodes = (product) => {
  return product.prices?.some(price => price.barcode != null);
};

export const sortProducts = (products, sortField, sortDirection) => {
  return [...products].sort((a, b) => {
    if (sortField === SORT_OPTIONS.PRICE) {
      const aPrice = Math.min(...(a.prices?.map(p => p.price) || [0]));
      const bPrice = Math.min(...(b.prices?.map(p => p.price) || [0]));
      return sortDirection === SORT_DIRECTIONS.ASC ? aPrice - bPrice : bPrice - aPrice;
    }

    if (sortField === SORT_OPTIONS.UPDATED || sortField === SORT_OPTIONS.CREATED) {
      return sortDirection === SORT_DIRECTIONS.ASC
        ? new Date(a[sortField]) - new Date(b[sortField])
        : new Date(b[sortField]) - new Date(a[sortField]);
    }

    const aValue = (a[sortField] || '').toLowerCase();
    const bValue = (b[sortField] || '').toLowerCase();
    return sortDirection === SORT_DIRECTIONS.ASC
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });
};

export const filterProducts = (products, filters) => {
  return products.filter(product => {
    // Filter by images
    if (filters.showOnlyWithImages && !product.images?.length) {
      return false;
    }

    // Filter by category
    if (filters.category !== 'all' && product.category !== filters.category) {
      return false;
    }

    // Filter by subcategory
    if (filters.subcategory !== 'all') {
      if (filters.subcategory === 'blank' && product.subcategory) {
        return false;
      }
      if (filters.subcategory !== 'blank' && product.subcategory !== filters.subcategory) {
        return false;
      }
    }

    // Filter by search term
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      return (
        product.product_name.toLowerCase().includes(searchLower) ||
        product.sku.toLowerCase().includes(searchLower)
      );
    }

    return true;
  });
};

export const getUniqueCategories = (products) => {
  return [...new Set(products.map(product => product.category).filter(Boolean))].sort();
};

export const getUniqueSubcategories = (products, selectedCategory = 'all') => {
  const relevantProducts = selectedCategory === 'all'
    ? products
    : products.filter(product => product.category === selectedCategory);

  return ['blank', ...new Set(
    relevantProducts
      .map(product => product.subcategory)
      .filter(Boolean)
  )].sort();
};

export const processProductImages = async (product, existingImages, fetchImages) => {
  if (!product.images?.length || existingImages[product.id]?.imageUrl) {
    return null;
  }

  try {
    const images = await fetchImages(product.id);
    return {
      productId: product.id,
      imageUrl: images[0]?.detailUrl || images[0]?.thumbnailUrl || null,
      thumbnailUrl: images[0]?.thumbnailUrl || null,
      detailUrl: images[0]?.detailUrl || null
    };
  } catch (error) {
    console.error(`Error fetching images for product ${product.id}:`, error);
    return null;
  }
};

export const getProductDisplayImage = (product, productImages) => {
  if (!product || !productImages[product.id]) return null;
  return productImages[product.id].imageUrl ||
    productImages[product.id].detailUrl ||
    productImages[product.id].thumbnailUrl;
};
