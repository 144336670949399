import React from 'react';
import { Box, Typography, Button, Container, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

function LandingPage({ isLoading }) {
  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to CraftHub360
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          The ultimate platform for craftsmen and artisans
        </Typography>
        <Typography variant="body1" paragraph>
          Manage your products, create lists, and grow your craft business with our powerful tools.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" component={Link} to="/register" sx={{ mr: 2 }}>
            Register Now
          </Button>
          <Button variant="outlined" color="primary" component={Link} to="/login">
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LandingPage;
