import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { refreshTenantTheme } from '../hooks/useTenantTheme';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import PageLayout from './PageLayout';

// Main component
const AdminConfigPage = () => {
  const [config, setConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await api.get('/config/getTenantInfo');
      setConfig(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching config:', error);
      setError('Failed to load configuration. Please try again.');
      setIsLoading(false);
    }
  };

  const handleInputChange = (key, value) => {
    setConfig(prevConfig => ({ ...prevConfig, [key]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.put('/config/updateConfig', config);
      setSuccess('Configuration updated successfully');
      await refreshTenantTheme();
    } catch (error) {
      console.error('Error updating config:', error);
      setError('Failed to update configuration. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    </PageLayout>
  );

  return (
    <PageLayout>
      <Typography variant="h1" gutterBottom>Settings</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Company Name"
          value={config.name || ''}
          onChange={(e) => handleInputChange('name', e.target.value)}
          variant="outlined"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? 'Updating...' : 'Update Config'}
        </Button>
      </Box>
    </PageLayout>
  );
};

export default AdminConfigPage;
