// src/components/products/ProductToolbar.js
import React from 'react';
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Checkbox,
  Typography,
  Tooltip
} from '@mui/material';
import {
  GridView as GridViewIcon,
  ViewList as ListViewIcon
} from '@mui/icons-material';

const ProductToolbar = ({
  viewMode,
  onViewModeChange,
  showOnlyWithImages,
  onShowOnlyWithImagesChange
}) => {
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      onViewModeChange(newMode);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}
    >
      <ToggleButtonGroup
        value={viewMode}
        exclusive
        onChange={handleViewModeChange}
        aria-label="view mode"
      >
        <Tooltip title="List View (Alt + L)" arrow>
          <ToggleButton value="list" aria-label="list view">
            <ListViewIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Grid View (Alt + G)" arrow>
          <ToggleButton value="grid" aria-label="grid view">
            <GridViewIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      <FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={showOnlyWithImages}
            onChange={(e) => onShowOnlyWithImagesChange(e.target.checked)}
            id="show-images-only"
          />
          <Typography
            variant="body2"
            component="label"
            htmlFor="show-images-only"
            sx={{ cursor: 'pointer' }}
          >
            Show only products with images
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );
};

export default ProductToolbar;
