import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { Link } from 'react-router-dom';

import {
  Autocomplete, TextField, Button, Grid, Paper, Typography, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress,
  Box, Divider, Card, CardMedia, CardActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Alert from '@mui/material/Alert';
import PageLayout from './PageLayout';
import ImageUploaderEditor from './ImageUploaderEditor'; // Import the new component

function ProductForm({ setGlobalAlert }) {
  const [product, setProduct] = useState({
    product_name: '',
    sku: '',
    cost: '',
    category: '',
    subcategory: '',
    size: '',
    material: '',
    weight: '',
    is_digital: false,
    is_subscription: false,
    ingredients: '',
    manufacturing_instructions: '',
    file_link: '',
    picture_collection_link: '',
    inventory_control: false,
    current_stock: ''
  });
  const [prices, setPrices] = useState([]);
  const [newPrice, setNewPrice] = useState({ channel: '', price: '', barcode: '' });
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    if (id) {
      fetchProduct();
    }
    fetchCategories();
    fetchSubcategories();
  }, [id]);

  const fetchCategories = async () => {
    try {
      const response = await api.get('/products/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setAlert({ open: true, message: 'Error fetching categories', severity: 'error' });
    }
  };

  const fetchSubcategories = async () => {
    try {
      const response = await api.get('/products/subcategories');
      setSubcategories(response.data.filter(subcategory => subcategory != null && subcategory !== ''));
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setAlert({ open: true, message: 'Error fetching subcategories', severity: 'error' });
    }
  };

  const fetchProduct = async (productId) => {
    try {
      const response = await api.get(`/products/${id}`);
      const productData = response.data;

      // Filter out invalid prices
      const validPrices = productData.prices.map((price) => ({
        ...price,
        price: price.price != null ? price.price : 0 // Default to 0 if price is null
      }));

      setProduct(response.data);
      setPrices(validPrices);

      // Fetch images
      const imagesResponse = await api.get(`/products/${id}/images`);
      setImages(imagesResponse.data);

    } catch (error) {
      console.error('Error fetching product:', error);
      setAlert({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;

    if (['cost', 'price', 'weight', 'current_stock'].includes(name)) {
      updatedValue = value === '' ? '' : Number(value);
    }

    setProduct({ ...product, [name]: updatedValue });
  };

  const handleCategoryChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setProduct({ ...product, category: newValue.trim() });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setProduct({ ...product, category: newValue.inputValue.trim() });
    } else {
      setProduct({ ...product, category: newValue || '' });
    }
  };

  const handleSubcategoryChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setProduct({ ...product, subcategory: newValue.trim() });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setProduct({ ...product, subcategory: newValue.inputValue.trim() });
    } else {
      setProduct({ ...product, subcategory: newValue || '' });
    }
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setNewPrice({
      ...newPrice,
      [name]: name === 'price' ? (value === '' ? '' : Number(value)) : value
    });
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });
  };

  const handleImageUpload = async (croppedImageUrl) => {
    setUploadingImage(true);
    try {
      // Convert base64 to blob
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();

      // Create FormData and append image
      const formData = new FormData();
      formData.append('image', blob, 'product-image.jpg');

      // Upload image to backend
      const uploadResponse = await api.post(`/products/${id}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Add new image to state
      const newImage = uploadResponse.data;
      setImages([...images, newImage]);
      setAlert({ open: true, message: 'Image uploaded successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error uploading image:', error);
      setAlert({ open: true, message: 'Error uploading image', severity: 'error' });
    } finally {
      setUploadingImage(false);
    }
  };

  const handleImageDelete = async (imageId) => {
    try {
      await api.delete(`/products/${id}/images/${imageId}`);
      setImages(images.filter((image) => image.id !== imageId));
      setAlert({ open: true, message: 'Image deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting image:', error);
      setAlert({ open: true, message: 'Error deleting image', severity: 'error' });
    }
  };

  const handleImageUpdate = async (imageId, croppedImageUrl) => {
    setUploadingImage(true);
    try {
      // Convert base64 to blob
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();

      // Create FormData and append image
      const formData = new FormData();
      formData.append('image', blob, 'product-image.jpg');

      // Update image in backend
      const updateResponse = await api.put(`/products/${id}/images/${imageId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Update image in state
      const updatedImage = updateResponse.data;
      setImages(images.map(img => img.id === imageId ? updatedImage : img));
      setAlert({ open: true, message: 'Image updated successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error updating image:', error);
      setAlert({ open: true, message: 'Error updating image', severity: 'error' });
    } finally {
      setUploadingImage(false);
    }
  };

  // Create ImageUploaderEditor instance
  const imageEditor = ImageUploaderEditor({
    onImageEditComplete: handleImageUpload,
    onImageUpdate: handleImageUpdate
  });

  const addPrice = () => {
    if (!newPrice.channel.trim() || newPrice.price === '' || isNaN(newPrice.price)) {
      setAlert({ open: true, message: 'Please enter a valid channel and price.', severity: 'error' });
      return;
    }

    setPrices([...prices, { ...newPrice, price: Number(newPrice.price) }]);
    setNewPrice({ channel: '', price: '', barcode: '' });
    setAlert({ open: true, message: 'Price added successfully!', severity: 'success' });
  };

  const removePrice = (index) => {
    const updatedPrices = prices.filter((_, i) => i !== index);
    setPrices(updatedPrices);
    setAlert({ open: true, message: 'Price removed successfully!', severity: 'success' });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldErrors({});

    try {
      const cleanedProduct = {
        ...product,
        cost: product.cost === '' ? null : parseFloat(product.cost) || null,
        weight: product.weight === '' ? null : parseFloat(product.weight) || null,
        current_stock: product.current_stock === '' ? null : parseInt(product.current_stock) || null,
        prices, // Include prices array
        productImages: images.map(img => ({ id: img.id })) // Include image IDs
      };

      const { created_at, updated_at, ...restProductData } = cleanedProduct;

      if (id) {
        await api.put(`/products/${id}`, restProductData);
        setGlobalAlert({ message: 'Product updated successfully!', severity: 'success' });
      } else {
        await api.post('/products', restProductData);
        setGlobalAlert({ message: 'Product created successfully!', severity: 'success' });
      }
      navigate('/products');
    } catch (error) {
      console.error('Error saving product:', error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        if (errors) {
          setFieldErrors(errors);
        }
        setGlobalAlert({ message: message || 'An error occurred while saving the product', severity: 'error' });
      } else {
        setGlobalAlert({ message: 'An unexpected error occurred', severity: 'error' });
      }
    }
  };

  return (
    <PageLayout>
      <Typography variant="h1" gutterBottom>
        {id ? 'Edit Product' : 'Create New Product'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Product Name"
              name="product_name"
              value={product.product_name || ''}
              onChange={handleChange}
              variant="outlined"
              error={!!fieldErrors.product_name}
              helperText={fieldErrors.product_name}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="SKU"
              name="sku"
              value={product.sku || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Product Images
            </Typography>


            {imageEditor.component}

            {uploadingImage && <CircularProgress size={24} />}

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {images.map((image) => (
                <Grid item xs={12} sm={4} key={image.id}>
                  <Card>
                    <CardMedia
                      component="img"
                      width="300"
                      image={image.detailUrl}
                      alt={`Product image ${image.id}`}
                    />
                    <CardActions>
                      <IconButton
                        onClick={() => imageEditor.handleEditImage(image.detailUrl, image.id)}
                        size="small"
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => handleImageDelete(image.id)}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Prices and Barcodes
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Channel</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Barcode (Optional)</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prices.map((price, index) => (
                    <TableRow key={index}>
                      <TableCell>{price.channel}</TableCell>
                      <TableCell>
                        ${typeof price.price === 'number' ? price.price.toFixed(2) : 'N/A'}
                      </TableCell>
                      <TableCell>{price.barcode || 'N/A'}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => removePrice(index)} color="error" data-cy="delete-button">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        label="Channel"
                        name="channel"
                        value={newPrice.channel || ''}
                        onChange={handlePriceChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label="Price"
                        name="price"
                        type="number"
                        value={newPrice.price || ''}
                        onChange={handlePriceChange}
                        InputProps={{ startAdornment: '$' }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label="Barcode (Optional)"
                        name="barcode"
                        value={newPrice.barcode || ''}
                        onChange={handlePriceChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={addPrice} variant="contained" color="primary">
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Classification
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={product.category}
              onChange={handleCategoryChange}
              filterOptions={(options, params) => {
                const filtered = options.filter(option =>
                  option.toLowerCase().includes(params.inputValue.toLowerCase())
                );

                if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={categories}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => <li {...props}>{option.title || option}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField {...params}
                  label="Category"
                  fullWidth
                  error={!!fieldErrors.category}
                  helperText={fieldErrors.category}
                  inputProps={{ ...params.inputProps, 'data-cy': 'category-input' }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={product.subcategory}
              onChange={handleSubcategoryChange}
              filterOptions={(options, params) => {
                const filtered = options.filter(option =>
                  option.toLowerCase().includes(params.inputValue.toLowerCase())
                );

                if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={subcategories}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => <li {...props}>{option.title || option}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Subcategory" fullWidth inputProps={{ ...params.inputProps, 'data-cy': 'subcategory-input' }} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Product Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Estimated Production Cost"
              name="cost"
              type="number"
              value={product.cost || ''}
              onChange={handleChange}
              variant="outlined"
              InputProps={{ startAdornment: '$' }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Size"
              name="size"
              value={product.size}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Weight"
              name="weight"
              type="number"
              value={product.weight || ''}
              onChange={handleChange}
              variant="outlined"
              InputProps={{ endAdornment: 'kg' }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Material"
              name="material"
              value={product.material || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Materials List"
              name="ingredients"
              value={product.ingredients || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Manufacturing Instructions"
              name="manufacturing_instructions"
              value={product.manufacturing_instructions || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Linked Designs
            </Typography>

            {product.designs?.length > 0 ? (
              <Box sx={{ mb: 2 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Design Name</TableCell>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {product.designs.map((design) => (
                        <TableRow key={design.id}>
                          <TableCell>
                            <Link
                              to={`/designs/${design.id}/edit`}
                            >
                              {design.name}
                            </Link>
                          </TableCell>
                          {/* <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => {
                                // Optional: Add ability to unlink design
                                // You'd need to implement this API endpoint
                                // handleUnlinkDesign(design.id)
                              }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Typography color="textSecondary" sx={{ mb: 2 }}>
                No designs linked to this product
              </Typography>
            )}

            {/* <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={`/designs/new?productId=${id}`}
            >
              Create New Design for Product
            </Button> */}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                {id ? 'Update' : 'Create'} Product
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

    </PageLayout>
  );
}

export default ProductForm;
