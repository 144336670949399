// src/components/lists/components/ListToolbar.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const ListToolbar = ({ onCreateList, totalLists }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2
    }}>
      <Typography variant="h6" component="h1">
        Lists ({totalLists})
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={onCreateList}
      >
        Create New List
      </Button>
    </Box>
  );
};

export default ListToolbar;
