import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../utils/api';

import { TextField, Button, Box, Typography } from '@mui/material';
import PageLayout from './PageLayout';

const EditList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listName, setListName] = useState('');

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await api.get(`/lists/${id}`);
        setListName(response.data.name);
      } catch (error) {
        console.error('Error fetching list:', error);
      }
    };
    fetchList();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/lists/${id}`, { name: listName });
      navigate('/lists');
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  return (
    <PageLayout>
      <Typography variant="h1" gutterBottom>Edit List</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="List Name"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Update List
        </Button>
      </Box>
    </PageLayout>
  );
};

export default EditList;
