// cropImage.js
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    // Add crossOrigin attribute
    image.crossOrigin = 'anonymous';
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
  try {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    // Set proper canvas dimensions taking into account pixel ratio
    const pixelRatio = window.devicePixelRatio;
    canvas.width = pixelCrop.width * pixelRatio;
    canvas.height = pixelCrop.height * pixelRatio;

    // Scale context for retina/HiDPI displays
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    // Calculate dimensions
    const rotRad = (rotation * Math.PI) / 180;
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // Create a temporary canvas for rotation if needed
    let tempCanvas;
    if (rotation !== 0) {
      tempCanvas = document.createElement('canvas');
      tempCanvas.width = bBoxWidth;
      tempCanvas.height = bBoxHeight;
      const tempCtx = tempCanvas.getContext('2d');
      if (!tempCtx) {
        throw new Error('No 2d context');
      }

      tempCtx.translate(bBoxWidth / 2, bBoxHeight / 2);
      tempCtx.rotate(rotRad);
      tempCtx.translate(-image.width / 2, -image.height / 2);
      tempCtx.drawImage(image, 0, 0);
    }

    // Draw the cropped image
    ctx.drawImage(
      rotation !== 0 ? tempCanvas : image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // Convert canvas to blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        },
        'image/jpeg',
        1
      );
    });
  } catch (error) {
    console.error('Error in getCroppedImg:', error);
    throw error;
  }
};

// Helper function to calculate rotated dimensions
const rotateSize = (width, height, rotation) => {
  const rotRad = (rotation * Math.PI) / 180;
  const absRotation = Math.abs(rotRad);
  const newWidth = Math.abs(width * Math.cos(absRotation)) + Math.abs(height * Math.sin(absRotation));
  const newHeight = Math.abs(width * Math.sin(absRotation)) + Math.abs(height * Math.cos(absRotation));
  return { width: newWidth, height: newHeight };
};
