// src/components/lists/components/ListTable.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography
} from '@mui/material';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { ListActions } from './ListActions';

const formatValue = (value) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return '$0.00';
  }
  return `$${value.toFixed(2)}`;
};

const ListTable = ({
  lists,
  onDelete,
  sortField,
  sortDirection,
  onSort
}) => {
  const navigate = useNavigate();

  const SortableTableCell = ({ field, children }) => (
    <TableCell
      onClick={() => onSort(field)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {children}
        {sortField === field && (
          sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
        )}
      </Box>
    </TableCell>
  );

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'primary.main' }}>
            <SortableTableCell field="name">Name</SortableTableCell>
            <SortableTableCell field="uniqueItemCount">Unique Products</SortableTableCell>
            <SortableTableCell field="totalQuantity">Total Items</SortableTableCell>
            <SortableTableCell field="totalValue">Total Value</SortableTableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lists.map((list) => (
            <TableRow key={list.id} hover>
              <TableCell component={Link} to={`/lists/${list.id}`}>
                <Typography variant="body2">{list.name}</Typography>
              </TableCell>
              <TableCell>{list.uniqueItemCount || 0}</TableCell>
              <TableCell>{list.totalQuantity || 0}</TableCell>
              <TableCell>{formatValue(list.totalValue)}</TableCell>
              <TableCell>
                <ListActions
                  onEdit={() => navigate(`/lists/${list.id}/edit`)}
                  onDelete={() => onDelete(list.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListTable;
