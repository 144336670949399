// src/components/products/ProductList.js
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { productService, listService } from '../../services';
import { handleError } from '../../utils/errorHandler';
import { SORT_OPTIONS, SORT_DIRECTIONS, LOCAL_STORAGE_KEYS } from '../../constants';
import { LoadingSpinner, ErrorMessage } from '../common';
import PageLayout from '../PageLayout';
import ProductToolbar from './ProductToolbar';
import ProductFilters from './ProductFilters';
import ProductActions from './ProductActions';
import ProductGrid from './ProductGrid';
import ProductTable from './ProductTable';

function ProductList() {
  const navigate = useNavigate();

  // View State
  const [viewMode, setViewMode] = useLocalStorage(LOCAL_STORAGE_KEYS.VIEW_MODE, 'list');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Data State
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productImages, setProductImages] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [lists, setLists] = useState([]);

  // Filter State
  const [filterState, setFilterState] = useLocalStorage(LOCAL_STORAGE_KEYS.FILTER_STATE, {
    searchTerm: '',
    category: 'all',
    subcategory: 'all',
    showOnlyWithImages: false,
    sortField: SORT_OPTIONS.UPDATED,
    sortDirection: SORT_DIRECTIONS.DESC
  });

  // Handlers
  const handleProductSelect = (productId) => {
    setSelectedProducts(prev =>
      prev.includes(productId)
        ? prev.filter(id => id !== productId)
        : [...prev, productId]
    );
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}/edit`);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await productService.delete(productId);
      setProducts(products.filter(p => p.id !== productId));
    } catch (error) {
      setError(handleError(error));
    }
  };

  const handleAddToList = async (listId) => {
    if (!listId || selectedProducts.length === 0) return;

    try {
      await listService.addProductsToList(listId, selectedProducts);
      setSelectedProducts([]);
    } catch (error) {
      setError(handleError(error));
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilterState(prev => ({ ...prev, ...newFilters }));
  };

  const handleViewModeChange = (newMode) => {
    if (newMode) setViewMode(newMode);
  };

  // Effects
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const [productsData, listsData] = await Promise.all([
          productService.getAll(),
          listService.getAll()
        ]);
        setProducts(productsData);
        setLists(listsData);
      } catch (error) {
        setError(handleError(error));
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchProductImages = async () => {
      const productsNeedingImages = products.filter(
        product => product.images?.length > 0 && !productImages[product.id]?.imageUrl
      );

      if (productsNeedingImages.length === 0) return;

      const imagePromises = productsNeedingImages.map(async (product) => {
        const images = await productService.getImages(product.id);
        return {
          productId: product.id,
          imageUrl: images[0]?.detailUrl || images[0]?.thumbnailUrl || null,
          thumbnailUrl: images[0]?.thumbnailUrl || null,
          detailUrl: images[0]?.detailUrl || null
        };
      });

      try {
        const newImages = await Promise.all(imagePromises);
        setProductImages(prev => {
          const updated = { ...prev };
          newImages.forEach(image => {
            updated[image.productId] = image;
          });
          return updated;
        });
      } catch (error) {
        console.error('Error fetching product images:', error);
      }
    };

    fetchProductImages();
  }, [products]);

  useEffect(() => {
    let filtered = [...products];

    // Filter by images
    if (filterState.showOnlyWithImages) {
      filtered = filtered.filter(product => productImages[product.id]?.imageUrl != null);
    }

    // Filter by category
    if (filterState.category !== 'all') {
      filtered = filtered.filter(product => product.category === filterState.category);
    }

    // Filter by subcategory
    if (filterState.subcategory !== 'all') {
      if (filterState.subcategory === 'blank') {
        filtered = filtered.filter(product => !product.subcategory);
      } else {
        filtered = filtered.filter(product => product.subcategory === filterState.subcategory);
      }
    }

    // Filter by search term
    if (filterState.searchTerm) {
      const searchLower = filterState.searchTerm.toLowerCase();
      filtered = filtered.filter(product =>
        product.product_name.toLowerCase().includes(searchLower) ||
        product.sku.toLowerCase().includes(searchLower)
      );
    }

    // Sort products
    filtered.sort((a, b) => {
      if (filterState.sortField === SORT_OPTIONS.PRICE) {
        const aPrice = Math.min(...(a.prices?.map(p => p.price) || [0]));
        const bPrice = Math.min(...(b.prices?.map(p => p.price) || [0]));
        return filterState.sortDirection === SORT_DIRECTIONS.ASC ? aPrice - bPrice : bPrice - aPrice;
      }

      if (filterState.sortField === SORT_OPTIONS.UPDATED || filterState.sortField === SORT_OPTIONS.CREATED) {
        return filterState.sortDirection === SORT_DIRECTIONS.ASC
          ? new Date(a[filterState.sortField]) - new Date(b[filterState.sortField])
          : new Date(b[filterState.sortField]) - new Date(a[filterState.sortField]);
      }

      const aValue = (a[filterState.sortField] || '').toLowerCase();
      const bValue = (b[filterState.sortField] || '').toLowerCase();
      return filterState.sortDirection === SORT_DIRECTIONS.ASC
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setFilteredProducts(filtered);
  }, [products, filterState, productImages]);

  if (loading) return <PageLayout><LoadingSpinner fullHeight /></PageLayout>;
  if (error) return <PageLayout><ErrorMessage message={error} /></PageLayout>;

  return (
    <PageLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <ProductToolbar
          viewMode={viewMode}
          onViewModeChange={handleViewModeChange}
          showOnlyWithImages={filterState.showOnlyWithImages}
          onShowOnlyWithImagesChange={(show) => handleFilterChange({ showOnlyWithImages: show })}
        />

        <ProductFilters
          filterState={filterState}
          onFilterChange={handleFilterChange}
          products={products}
        />

        <ProductActions
          selectedCount={selectedProducts.length}
          onCreateNew={() => navigate('/products/add')}
          lists={lists}
          onAddToList={handleAddToList}
        />

        {viewMode === 'grid' ? (
          <ProductGrid
            products={filteredProducts}
            productImages={productImages}
            selectedProducts={selectedProducts}
            onProductSelect={handleProductSelect}
            onProductClick={handleProductClick}
            onDeleteProduct={handleDeleteProduct}
          />
        ) : (
          <ProductTable
            products={filteredProducts}
            productImages={productImages}
            selectedProducts={selectedProducts}
            onProductSelect={handleProductSelect}
            onProductClick={handleProductClick}
            onDeleteProduct={handleDeleteProduct}
            sortField={filterState.sortField}
            sortDirection={filterState.sortDirection}
            onSort={(field) => handleFilterChange({
              sortField: field,
              sortDirection: filterState.sortField === field ?
                (filterState.sortDirection === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC) :
                SORT_DIRECTIONS.ASC
            })}
          />
        )}
      </Box>
    </PageLayout>
  );
}

export default ProductList;
