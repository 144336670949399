// components/common/LoadingSpinner.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const LoadingSpinner = ({ fullHeight = false }) => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: fullHeight ? '100vh' : 'auto',
    p: 4
  }}>
    <CircularProgress />
  </Box>
);
