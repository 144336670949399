// src/components/products/components/ProductActions.js
import { IconButton, Box } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

export const ProductActions = ({ onEdit, onDelete, size = 'small' }) => (
  <Box sx={{ display: 'flex', gap: 1 }}>
    <IconButton
      size={size}
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
    >
      <EditIcon />
    </IconButton>
    <IconButton
      size={size}
      color="error"
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
    >
      <DeleteIcon />
    </IconButton>
  </Box>
);
