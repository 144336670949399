import React from 'react';
import { Container, Box } from '@mui/material';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useTenantTheme } from '../hooks/useTenantTheme'; // Custom hook for the theme

const PageLayout = ({ children }) => {
  const theme = useTenantTheme(); // Get the theme from your custom hook

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normalize and apply baseline CSS */}
      <Container maxWidth="xl" sx={{ padding: '20px' }}>
        <Box sx={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          {children}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageLayout;
