// utils/urlHelpers.js

const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('crafthub360.com')) {
    return 'production';
  } else if (hostname.includes('staging.crafthub360.com')) {
    return 'staging';
  } else {
    return 'development';
  }
};

export const getTenantUrl = (subdomain) => {
  const env = getEnvironment();

  switch (env) {
    case 'production':
      return `https://${subdomain}.crafthub360.com`;
    case 'staging':
      return `https://${subdomain}.staging.crafthub360.com`;
    case 'development':
    default:
      return `http://${subdomain}.ch360.local:3000`;
  }
};

export const getMainUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case 'production':
      return 'https://www.crafthub360.com';
    case 'staging':
      return 'https://www.staging.crafthub360.com';
    case 'development':
    default:
      return 'http://www.ch360.local:3000';
  }
};

export const getApiUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case 'production':
      return 'https://crafthub360.com/api';
    case 'staging':
      return 'https://staging.crafthub360.com/api';
    case 'development':
    default:
      return 'http://localhost:5000';
  }

};
