import { useState, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import api from '../utils/api';
import { getMainUrl } from '../utils/urlHelpers';

const TENANT_INFO_KEY = 'tenantInfo';
const TENANT_INFO_TIMESTAMP_KEY = 'tenantInfoTimestamp';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Function to create a theme based on tenant info
const createTenantTheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: '#58A4B0',
      },
      secondary: {
        main: '#A9BCD0',
      },
      background: {
        default: '#D8DBE2',
        paper: '#ffffff',
      },
      text: {
        primary: '#373F51',
        secondary: '#666666',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      h1: {
        fontSize: '2rem',
        fontWeight: 600,
        color: '#373F51',
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 500,
        color: '#373F51',
      },
    },
    components: {
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',  // Ensure no hover fill
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#373F51',  // Dark tooltip background
            color: '#ffffff',  // White text for better contrast
            fontSize: '0.875rem',  // Slightly smaller tooltip text
          },
        },
      },
      // Table styling
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',  // Subtle border between rows
            padding: '12px',  // Increase padding inside cells
            fontSize: '1rem',  // Increase table cell font size
          },
          head: {
            backgroundColor: '#A9BCD0',  // Powder blue for headers
            color: '#373F51',  // Charcoal for header text
            fontWeight: 600,  // Bolder header text
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#f1f5f8',  // Light background color change on hover
              cursor: 'pointer',  // Pointer cursor on hover for rows
            },
          },
        },
      },
      // Button styling
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#58A4B0',  // Moonstone
            color: '#ffffff',
            borderRadius: 8,
            padding: '10px 20px',
            margin: '0 10px 0 0',
            textTransform: 'none',  // Disable uppercase text
            '&:hover': {
              backgroundColor: '#377A88',  // Darker Moonstone on hover
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',  // Add shadow on hover
            },
            '&:disabled': {
              backgroundColor: 'rgba(88, 164, 176, 0.5)',  // Lighter Moonstone for disabled buttons
              color: '#ffffff',
            },
          },
        },
      },
      // AppBar styling
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: 0,  // Rounded corners
            color: '#ffffff',  // White text color for AppBar
            backgroundColor: '#445065',  // Slightly lighter charcoal for AppBar
            padding: '5px',  // Add padding around AppBar content
          },
        },
      },
      // Paper component (for cards, modals, etc.)
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 12,  // Rounded corners
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Deeper shadow for elevation
            padding: '20px',  // Padding for content inside Paper
          },
        },
      },
    },
  });
};

// Function to check if current domain is the main domain
const isMainDomain = () => {
  const currentUrl = new URL(window.location.href);
  const mainUrl = new URL(getMainUrl());
  return currentUrl.hostname === mainUrl.hostname;
};

// Function to fetch tenant info from API and cache it
const fetchAndStoreTenantInfo = async () => {
  if (isMainDomain()) {
    return null; // Return null for main domain, no need to fetch or store
  }

  try {
    const response = await api.get('/config/getTenantInfo');
    const tenantInfo = response.data;
    localStorage.setItem(TENANT_INFO_KEY, JSON.stringify(tenantInfo));
    localStorage.setItem(TENANT_INFO_TIMESTAMP_KEY, Date.now().toString());
    return tenantInfo;
  } catch (error) {
    console.error('Error fetching tenant info:', error);
    return null;
  }
};

// Function to get cached tenant info if it's still valid
const getCachedTenantInfo = () => {
  const cachedInfo = localStorage.getItem(TENANT_INFO_KEY);
  const timestamp = localStorage.getItem(TENANT_INFO_TIMESTAMP_KEY);

  if (cachedInfo && timestamp) {
    const age = Date.now() - parseInt(timestamp, 10);
    if (age < CACHE_DURATION) {
      return JSON.parse(cachedInfo);
    }
  }
  return null;
};

export const useTenantTheme = () => {
  const [theme, setTheme] = useState(createTenantTheme(null));
  const [isMainDomain, setIsMainDomain] = useState(false);

  useEffect(() => {
    const checkDomain = () => {
      const currentUrl = new URL(window.location.href);
      const mainUrl = new URL(getMainUrl());
      setIsMainDomain(currentUrl.hostname === mainUrl.hostname);
    };

    checkDomain();
  }, []);

  useEffect(() => {
    const loadTenantInfo = async () => {
      if (isMainDomain) {
        // Use default theme for main domain
        setTheme(createTenantTheme(null));
        return;
      }

      let tenantInfo = getCachedTenantInfo();

      if (!tenantInfo) {
        tenantInfo = await fetchAndStoreTenantInfo();
      } else {
        // Fetch new data in the background
        fetchAndStoreTenantInfo().then(newInfo => {
          if (newInfo && JSON.stringify(newInfo) !== JSON.stringify(tenantInfo)) {
            setTheme(createTenantTheme(newInfo));
          }
        });
      }

      if (tenantInfo) {
        setTheme(createTenantTheme(tenantInfo));
      }
    };

    loadTenantInfo();
  }, [isMainDomain]);

  return theme;
};

// Function to refresh the tenant theme and force a reload
export const refreshTenantTheme = async () => {
  await fetchAndStoreTenantInfo();
  window.location.reload(); // Force a full app reload
};

export default useTenantTheme;
