// src/components/Lists/ListFilters.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { FilterBar } from '../../common';

const ListFilters = ({ filterState, onFilterChange }) => {
  return (
    <FilterBar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search Lists"
            value={filterState.searchTerm}
            onChange={(e) => onFilterChange({ searchTerm: e.target.value })}
            placeholder="Search by list name"
          />
        </Grid>
      </Grid>
    </FilterBar>
  );
};

export default ListFilters;
