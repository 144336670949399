// src/components/products/components/ProductBarcodeStatus.js
import { Tooltip } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { hasBarcodes } from '../utils';

export const ProductBarcodeStatus = ({ product }) => {
  if (hasBarcodes(product)) {
    return (
      <Tooltip title="Has barcodes">
        <CheckCircleIcon color="success" />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="No barcodes">
      <CancelIcon color="error" />
    </Tooltip>
  );
};
