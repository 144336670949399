// src/components/products/ProductFilters.js
import React, { useMemo } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { FilterBar } from '../common/FilterBar';
import { getUniqueCategories, getUniqueSubcategories } from './utils';

const ProductFilters = ({ filterState, onFilterChange, products }) => {
  const categories = useMemo(() =>
    getUniqueCategories(products),
    [products]
  );

  const subcategories = useMemo(() =>
    getUniqueSubcategories(products, filterState.category),
    [products, filterState.category]
  );

  return (
    <FilterBar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Search"
            value={filterState.searchTerm}
            onChange={(e) => onFilterChange({ searchTerm: e.target.value })}
            placeholder="Search by name or SKU"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={filterState.category}
              onChange={(e) => {
                onFilterChange({
                  category: e.target.value,
                  subcategory: 'all' // Reset subcategory when category changes
                });
              }}
              label="Category"
            >
              <MenuItem value="all"><em>All Categories</em></MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Subcategory</InputLabel>
            <Select
              value={filterState.subcategory}
              onChange={(e) => onFilterChange({ subcategory: e.target.value })}
              label="Subcategory"
              disabled={filterState.category === 'all'}
            >
              <MenuItem value="all"><em>All Subcategories</em></MenuItem>
              <MenuItem value="blank"><em>(No Subcategory)</em></MenuItem>
              {subcategories.map((subcategory) => (
                subcategory !== 'blank' && (
                  <MenuItem key={subcategory} value={subcategory}>
                    {subcategory}
                  </MenuItem>
                )
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FilterBar>
  );
};

export default ProductFilters;
