import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box
} from '@mui/material';
import PageLayout from '../PageLayout';
import api from '../../utils/api';

function SupplierForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!!id);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    contact_name: '',
    email: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    const fetchSupplier = async () => {
      if (!id) return;

      try {
        const response = await api.get(`/materials/suppliers/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching supplier:', error);
        setError('Failed to load supplier data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSupplier();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await api.put(`/materials/suppliers/${id}`, formData);
      } else {
        await api.post('/materials/suppliers', formData);
      }
      navigate('/suppliers');
    } catch (error) {
      console.error('Error saving supplier:', error);
      setError('Failed to save supplier. Please try again.');
    }
  };

  if (loading) return <PageLayout><Typography>Loading...</Typography></PageLayout>;

  return (
    <PageLayout>
      <Typography variant="h4" gutterBottom>
        {id ? 'Edit Supplier' : 'Create Supplier'}
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact Person"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              multiline
              rows={3}
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {id ? 'Update' : 'Create'} Supplier
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default SupplierForm;
