import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Chip
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Upload as UploadIcon,
  Add as AddIcon
} from '@mui/icons-material';
import PageLayout from '../PageLayout';
import api from '../../utils/api';
import DesignFileUpload from './DesignFileUpload';

const DesignForm = ({ setGlobalAlert, onSuccess }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sourceType, setSourceType] = useState('self-created');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    author: '',
    sourceType: 'self-created',
    purchase_source: '',
    purchase_price: '',
    available_from_date: '',
    license_info: '',
    internal_designer: '',
    version: '1.0',
    tags: [],
    metadata: {},
    productIds: [],
    version: '1.0',
    internal_designer: ''
  });
  const [files, setFiles] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [previousSourceType, setPreviousSourceType] = useState(null);
  const [purchaseSources, setPurchaseSources] = useState([
    'Etsy',
    'Creative Fabrica',
    'Design Bundles',
    'Our Design Space',
    'SVG Salon',
    'Personal Creation'
  ]);
  const licenseOptions = [
    { value: 'commercial', label: 'Commercial Use License' },
    { value: 'personal', label: 'Personal Use Only' },
    { value: 'extended', label: 'Extended Commercial License' },
    { value: 'custom', label: 'Custom License' }
  ];
  const fileUploadRef = useRef();
  const [pendingTags, setPendingTags] = useState([]);

  useEffect(() => {
    const savedSources = localStorage.getItem('purchaseSources');
    if (savedSources) {
      setPurchaseSources(JSON.parse(savedSources));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const productsResponse = await api.get('/products');
        setAvailableProducts(productsResponse.data);

        if (id) {
          const designResponse = await api.get(`/designs/${id}`);
          const designData = designResponse.data;

          // Transform backend data to form format
          setFormData({
            name: designData.name || '',
            description: designData.description || '',
            source_type: designData.source_type || 'self-created',
            author: designData.author || '',
            purchase_source: designData.purchase_source || '',
            purchase_price: designData.purchase_price || '',
            available_from_date: designData.available_from_date ?
              new Date(designData.available_from_date).toISOString().split('T')[0] : '',
            license_info: designData.license_info || '',
            internal_designer: designData.internal_designer || '',
            version: designData.version || '1.0',
            metadata: designData.metadata?.reduce((acc, m) => ({ ...acc, [m.key]: m.value }), {}) || {},
            productIds: designData.products?.map(p => p.id) || []
          });

          const formattedTags = designData.tags?.map(t => t.tag
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          ) || [];
          setPendingTags(formattedTags);
          setFiles(designData.files || []);
          setSelectedProducts(designData.products || []);
          setSourceType(designData.source_type || 'self-created');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setGlobalAlert({ message: 'Error loading design data', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, setGlobalAlert]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSourceTypeChange = (event) => {
    const newSourceType = event.target.value;

    setFormData(prev => ({
      ...prev,
      source_type: newSourceType
    }));
  };

  const handleTagDelete = (tagToDelete) => {
    setPendingTags(prev => prev.filter(tag => tag !== tagToDelete));
  };

  const handleTagAdd = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const tagValue = event.target.value.trim();
      if (tagValue) {
        // Transform to lowercase and capitalize first letter for display
        const formattedTag = tagValue
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        if (!pendingTags.includes(formattedTag)) {
          setPendingTags(prev => [...prev, formattedTag]);
          event.target.value = '';
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors({});

    try {
      const submitData = {
        ...formData,
        tags: pendingTags,
        productIds: selectedProducts.map(p => p.id),
        // Transform dates to ISO format
        available_from_date: formData.available_from_date ?
          new Date(formData.available_from_date).toISOString() : null,
        license_info: formData.license_info || '',
        // Convert price to number if present
        purchase_price: formData.purchase_price ? Number(formData.purchase_price) : null
      };

      if (id) {
        await api.put(`/designs/${id}`, submitData);
        setGlobalAlert({ message: 'Design updated successfully', severity: 'success' });
      } else {
        const response = await api.post('/designs', submitData);

        // Upload any pending files
        if (fileUploadRef.current) {
          await fileUploadRef.current.uploadPendingFiles(response.data.id);
        }

        setGlobalAlert({ message: 'Design created successfully', severity: 'success' });
      }

      navigate('/designs');
    } catch (error) {
      console.error('Error saving design:', error);
      if (error.response?.data?.errors) {
        setFieldErrors(error.response.data.errors);
      }
      setGlobalAlert({
        message: error.response?.data?.message || 'Error saving design',
        severity: 'error'
      });
    }
  };

  const content = (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {/* Basic Information */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Basic Information</Typography>
          <TextField
            fullWidth
            label="Design Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Grid>

        {/* Source Type Selection */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>Source Type</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="sourceType"
              value={formData.source_type}
              onChange={(e) => {
                handleSourceTypeChange(e);
                handleChange(e);
              }}
            >
              <FormControlLabel
                value="purchased"
                control={<Radio />}
                label="Purchased"
              />
              <FormControlLabel
                value="self-created"
                control={<Radio />}
                label="Self Created"
              />
              <FormControlLabel
                value="commissioned"
                control={<Radio />}
                label="Commissioned"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Conditional Fields Based on Source Type */}
        <Grid item xs={12}>
          {formData.source_type === 'self-created' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Internal Designer"
                  name="internal_designer"
                  value={formData.internal_designer}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Version"
                  name="version"
                  value={formData.version}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Creation Date"
                  name="available_from_date"
                  type="date"
                  value={formData.available_from_date || ''}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
              </Grid>
            </Grid>
          )}

          {formData.source_type === 'purchased' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Purchase Source"
                  name="purchase_source"
                  value={formData.purchase_source}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Purchase Price"
                  name="purchase_price"
                  type="number"
                  value={formData.purchase_price}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Purchase Date"
                  name="available_from_date"
                  type="date"
                  value={formData.available_from_date || ''}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="License Type"
                  name="license_info"
                  value={formData.license_info || 'commercial'}
                  onChange={handleChange}
                >
                {licenseOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>
          )}

          {formData.source_type === 'commissioned' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Designer/Agency"
                  name="author"
                  value={formData.author}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Commission Cost"
                  name="purchase_price"
                  type="number"
                  value={formData.purchase_price}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Completion Date"
                  name="available_from_date"
                  type="date"
                  value={formData.available_from_date || ''}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Files */}
        <Grid item xs={12}>
          <DesignFileUpload
            designId={id}
            onFileChange={(files) => setFiles(files)}
            setGlobalAlert={setGlobalAlert}
            ref={fileUploadRef}
          />
        </Grid>

        {/* Tags */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>Tags</Typography>
          <Box sx={{ mb: 2 }}>
            {pendingTags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleTagDelete(tag)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            label="Add Tags"
            placeholder="Press Enter to add tags"
            onKeyDown={handleTagAdd}
          />
        </Grid>

        {/* Associated Products */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>Associated Products</Typography>
          <Autocomplete
            multiple
            options={availableProducts}
            getOptionLabel={(option) => `${option.product_name} (${option.sku})`}
            value={selectedProducts}
            onChange={(event, newValue) => {
              setSelectedProducts(newValue);
              setFormData(prev => ({
                ...prev,
                productIds: newValue.map(p => p.id)
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Products"
                placeholder="Search products"
              />
            )}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {id ? 'Update' : 'Create'} Design
            </Button>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );

  if (loading) {
    return <PageLayout><Typography>Loading...</Typography></PageLayout>;
  }

  return (
    <PageLayout>
      <Typography variant="h1" gutterBottom>
        {id ? 'Edit Design' : 'Create New Design'}
      </Typography>
      {content}
    </PageLayout>
  );
};

export default DesignForm;
