import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import {
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon
} from '@mui/icons-material';
import api from '../../utils/api';
import { ERROR_MESSAGES } from '../../constants';

const DesignFileUpload = forwardRef(({ designId, onFileChange, setGlobalAlert }, ref) => {
  const [files, setFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);

  useImperativeHandle(ref, () => ({
    uploadPendingFiles: async (newDesignId) => {
      const uploadPromises = pendingFiles.map(async (pendingFile) => {
        const formData = new FormData();
        formData.append('file', pendingFile.file);
        return api.post(`/designs/${newDesignId}/files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      });

      try {
        await Promise.all(uploadPromises);
        await fetchFiles(); // Refresh the files list
      } catch (error) {
        console.error('Error uploading pending files:', error);
        setGlobalAlert({ message: 'Error uploading some files', severity: 'error' });
      }
    }
  }));

  useEffect(() => {
    if (designId) {
      fetchFiles();
    }
  }, [designId]);

  const fetchFiles = async () => {
    try {
      const response = await api.get(`/designs/${designId}/files`);
      setFiles(response.data);
      setPendingFiles([]); // Clear pending files once we have a design ID
      if (onFileChange) {
        onFileChange(response.data);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      setGlobalAlert({ message: 'Error loading files', severity: 'error' });
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!designId) {
      // Store file for later upload
      const pendingFile = {
        id: `pending-${Date.now()}`,
        original_filename: file.name,
        file_type: file.type,
        file_size: file.size,
        file: file // Store the actual file object
      };
      const newPendingFiles = [...pendingFiles, pendingFile];
      setPendingFiles(newPendingFiles);
      if (onFileChange) {
        onFileChange([...files, ...newPendingFiles]);
      }
    } else {
      handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`/designs/${designId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const newFiles = [...files, response.data.file];
      setFiles(newFiles);
      if (onFileChange) {
        onFileChange(newFiles);
      }
      setGlobalAlert({ message: 'File uploaded successfully', severity: 'success' });
    } catch (error) {
      console.error('Error uploading file:', error);
      setGlobalAlert({ message: 'Error uploading file', severity: 'error' });
    }
  };

  const handleDeleteFile = async (fileId) => {
    if (fileId.toString().startsWith('pending-')) {
      // Remove pending file
      const newPendingFiles = pendingFiles.filter(f => f.id !== fileId);
      setPendingFiles(newPendingFiles);
      if (onFileChange) {
        onFileChange([...files, ...newPendingFiles]);
      }
      return;
    }

    try {
      await api.delete(`/designs/${designId}/files/${fileId}`);
      const newFiles = files.filter(f => f.id !== fileId);
      setFiles(newFiles);
      if (onFileChange) {
        onFileChange(newFiles);
      }
      setGlobalAlert({ message: 'File deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting file:', error);
      setGlobalAlert({ message: 'Error deleting file', severity: 'error' });
    }
  };

  const handleDownload = async (fileUrl, filename) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error('Error downloading file:', error);
      setGlobalAlert({
        message: ERROR_MESSAGES.FILE_DOWNLOAD,
        severity: 'error'
      });
    }
  };

  return (
    <Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" gutterBottom>Design Files</Typography>

      <input
        accept="*/*"
        style={{ display: 'none' }}
        id="upload-file"
        type="file"
        onChange={handleFileSelect}
      />
      <label htmlFor="upload-file">
        <Button
          variant="contained"
          component="span"
          startIcon={<UploadIcon />}
        >
          Upload File
        </Button>
      </label>

      <List>
        {/* Display pending files */}
        {pendingFiles.map((file) => (
          <ListItem key={file.id}>
            <ListItemText
              primary={`${file.original_filename} (Pending)`}
              secondary={`Type: ${file.file_type}, Size: ${(file.file_size / 1024).toFixed(2)} KB`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleDeleteFile(file.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}

        {/* Display uploaded files */}
        {files.map((file) => (
          <ListItem key={file.id}>
            <ListItemText
              primary={file.original_filename}
              secondary={`Type: ${file.file_type}, Size: ${(file.file_size / 1024).toFixed(2)} KB`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleDownload(file.url, file.original_filename)}
                sx={{ mr: 1 }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => handleDeleteFile(file.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
});

export default DesignFileUpload;
