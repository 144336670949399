import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { LoadingSpinner, ErrorMessage, FilterBar } from '../common';
import PageLayout from '../PageLayout';
import api from '../../utils/api';

function MaterialsList() {
  const navigate = useNavigate();
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterState, setFilterState] = useState({
    searchTerm: '',
    sortField: 'name',
    sortDirection: 'asc'
  });
  const [stockDialog, setStockDialog] = useState({
    open: false,
    materialId: null,
    currentStock: 0,
    adjustment: 0,
    reason: ''
  });

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      setLoading(true);
      const response = await api.get('/materials');
      setMaterials(response.data);
    } catch (error) {
      console.error('Error fetching materials:', error);
      setError('Failed to load materials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStockAdjustment = async () => {
    try {
      await api.post(`/materials/${stockDialog.materialId}/adjust-stock`, {
        quantity: parseInt(stockDialog.adjustment),
        reason: stockDialog.reason
      });
      fetchMaterials();
      setStockDialog({ ...stockDialog, open: false });
    } catch (error) {
      console.error('Error adjusting stock:', error);
      setError('Failed to adjust stock. Please try again.');
    }
  };

  const handleDelete = async (materialId) => {
    if (window.confirm('Are you sure you want to delete this material?')) {
      try {
        await api.delete(`/materials/${materialId}`);
        fetchMaterials();
      } catch (error) {
        console.error('Error deleting material:', error);
        setError('Failed to delete material. Please try again.');
      }
    }
  };

  const formatCost = (cost) => {
    if (cost === null || cost === undefined) return 'N/A';
    const numericCost = parseFloat(cost);
    return isNaN(numericCost) ? 'N/A' : `$${numericCost.toFixed(2)}`;
  };

  const sortedMaterials = [...materials].sort((a, b) => {
    const aValue = a[filterState.sortField]?.toString().toLowerCase();
    const bValue = b[filterState.sortField]?.toString().toLowerCase();
    return filterState.sortDirection === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  }).filter(material =>
    material.name.toLowerCase().includes(filterState.searchTerm.toLowerCase()) ||
    material.sku?.toLowerCase().includes(filterState.searchTerm.toLowerCase())
  );

  if (loading) return <PageLayout><LoadingSpinner /></PageLayout>;
  if (error) return <PageLayout><ErrorMessage message={error} /></PageLayout>;

  return (
    <PageLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">Materials</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/materials/add')}
          >
            Add Material
          </Button>
        </Box>

        <FilterBar>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search"
                value={filterState.searchTerm}
                onChange={(e) => setFilterState(prev => ({ ...prev, searchTerm: e.target.value }))}
                placeholder="Search by name or SKU"
              />
            </Grid>
          </Grid>
        </FilterBar>

        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Current Stock</TableCell>
                <TableCell>Reorder Point</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMaterials.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.name}</TableCell>
                  <TableCell>{material.sku}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => setStockDialog({
                        open: true,
                        materialId: material.id,
                        currentStock: material.stock?.[0]?.quantity || 0,
                        adjustment: 0,
                        reason: ''
                      })}
                    >
                      {material.stock?.[0]?.quantity || 0}
                    </Button>
                  </TableCell>
                  <TableCell>{material.stock?.[0]?.reorder_point || 0}</TableCell>
                  <TableCell>{formatCost(material.cost)}</TableCell>
                  <TableCell>{material.supplier?.name || 'N/A'}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => navigate(`/materials/${material.id}/edit`)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(material.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={stockDialog.open}
          onClose={() => setStockDialog({ ...stockDialog, open: false })}
        >
          <DialogTitle>Adjust Stock</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <Typography>Current Stock: {stockDialog.currentStock}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Adjustment"
                  type="number"
                  value={stockDialog.adjustment}
                  onChange={(e) => setStockDialog(prev => ({ ...prev, adjustment: e.target.value }))}
                  helperText="Use positive numbers to add stock, negative to remove"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Reason"
                  value={stockDialog.reason}
                  onChange={(e) => setStockDialog(prev => ({ ...prev, reason: e.target.value }))}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setStockDialog({ ...stockDialog, open: false })}>
              Cancel
            </Button>
            <Button
              onClick={handleStockAdjustment}
              variant="contained"
              disabled={!stockDialog.reason || !stockDialog.adjustment}
            >
              Update Stock
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </PageLayout>
  );
}

export default MaterialsList;
