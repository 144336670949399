import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
  Link,
  Grid,
  Alert,
} from '@mui/material';
import { TextField as FormikTextField } from 'formik-material-ui';
import ReCAPTCHA from "react-google-recaptcha";
import api from '../utils/api'; // Import the api instance
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  companyName: Yup.string().required('Company name is required'),
  companySize: Yup.string().required('Company size is required'),
  companyType: Yup.string().required('Company type is required'),
  referralSource: Yup.string().required('Please let us know how you heard about us'),
  description: Yup.string(),
  agreeTerms: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  companySize: '',
  companyType: '',
  referralSource: '',
  description: '',
  agreeTerms: false,
};

const companyTypes = [
  'Sole Proprietorship',
  'Limited Liability Company (LLC)',
  'Partnership',
  'Corporation',
  'S Corporation',
  'Cooperative',
];

const companySizes = [
  '1-10 employees',
  '11-50 employees',
  '51-200 employees',
  '201-500 employees',
  '501-1000 employees',
  '1000+ employees',
];

const referralSources = [
  'Search Engine',
  'Social Media',
  'Friend or Colleague',
  'Advertisement',
  'Blog or Publication',
  'Other',
];

const RegistrationForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const recaptchaRef = useRef();

  useEffect(() => {
    if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
      setRecaptchaError(true);
      console.error('REACT_APP_RECAPTCHA_SITE_KEY is not set in the environment');
    }
  }, []);

  const executeRecaptcha = () => {
    if (recaptchaRef.current) {
      return recaptchaRef.current.executeAsync();
    }
    return Promise.resolve(null);
  };

  const isFormFilled = (values) => {
    const requiredFields = [
      'firstName', 'lastName', 'email', 'phone', 'companyName',
      'companySize', 'companyType', 'referralSource'
    ];
    const filled = requiredFields.every(field => !!values[field]);
    return filled;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      if (!recaptchaToken) {
        setRecaptchaError(true);
        setSubmitting(false);
        return;
      }

      // Sending form data to backend API using the imported `api` instance
      const response = await api.post('/auth/register-interest', {
        ...values,
        recaptchaToken,
      });

      if (response.status === 201) {
        setSubmitSuccess(true);
      } else {
        console.error('Error submitting registration:', response.statusText);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (submitSuccess) {
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Thank you for your interest!
          </Typography>
          <Typography>
            We've received your registration and will be in touch soon.
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Register Interest
        </Typography>
        {recaptchaError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            There was an error with reCAPTCHA. Please try again later or contact support.
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isValid, values, submitForm, setFieldValue }) => {
            const isFormFilledValue = isFormFilled(values);
            const isButtonDisabled = !isValid || !isFormFilledValue || !values.agreeTerms;

            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="firstName"
                      label="First Name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="email"
                      label="Email"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="phone"
                      label="Phone"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      name="companyName"
                      label="Company Name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="companySize"
                      label="Company Size"
                      select
                      fullWidth
                      required
                    >
                      {companySizes.map((size) => (
                        <MenuItem key={size} value={size}>
                          {size}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={FormikTextField}
                      name="companyType"
                      label="Company Type"
                      select
                      fullWidth
                      required
                    >
                      {companyTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      name="referralSource"
                      label="How did you hear about us?"
                      select
                      fullWidth
                      required
                    >
                      {referralSources.map((source) => (
                        <MenuItem key={source} value={source}>
                          {source}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      name="description"
                      label="Brief description of your needs"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          type="checkbox"
                          name="agreeTerms"
                          checked={values.agreeTerms}
                          onChange={() => {
                            if (!values.agreeTerms) {
                              setTermsDialogOpen(true);
                            } else {
                              setFieldValue('agreeTerms', false);
                            }
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I agree to the {' '}
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setTermsDialogOpen(true);
                            }}
                            style={{ padding: 0, textTransform: 'none', verticalAlign: 'baseline' }}
                          >
                            terms and conditions
                          </Button>
                          *
                        </Typography>
                      }
                    />

                    {termsDialogOpen && (
                      <TermsAndConditionsDialog
                        open={termsDialogOpen}
                        onClose={() => {
                          console.log("Dialog closed");
                          setTermsDialogOpen(false);
                        }}
                        onAccept={() => {
                          console.log("Terms accepted");
                          setTermsDialogOpen(false);
                          setFieldValue('agreeTerms', true);
                        }}
                      />
                    )}

                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      fullWidth
                      {...(!isValid || !isFormFilled(values) ? { disabled: true } : {})}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Container>
  );
};

export default RegistrationForm;
