// ImageUploaderEditor.js
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImage';
import {
  Dialog,
  IconButton,
  Button,
  CircularProgress,
  Slider,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardActions,
  Tooltip
} from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const ImageUploaderEditor = ({ onImageEditComplete, onImageUpdate }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [editingImageId, setEditingImageId] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedArea, setCroppedArea] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [open, setOpen] = useState(false);

  const aspectRatio = 1;  // Square cropping

  const resetCropState = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setCroppedArea(null);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setImageSrc(reader.result);
        setEditingImageId(null); // Reset editing ID for new upload
        setOpen(true);
        resetCropState();
      };
    }
  };

  const handleEditImage = async (imageUrl, imageId) => {
    const response = await fetch(imageUrl, {
      mode: 'cors',
      credentials: 'include'
    });
    const blob = await response.blob();
    const localObjectUrl = URL.createObjectURL(blob);

    setImageSrc(imageUrl);
    setEditingImageId(imageId);
    setOpen(true);
    resetCropState();
  };

  const handleCropAndSave = async () => {
    setUploadingImage(true);
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedArea, rotation);
      setUploadingImage(false);
      setOpen(false);

      if (editingImageId) {
        // Handle update of existing image
        onImageUpdate(editingImageId, croppedImage);
      } else {
        // Handle new image upload
        onImageEditComplete(croppedImage);
      }

      // Reset state
      setEditingImageId(null);
      setImageSrc(null);
      resetCropState();
    } catch (error) {
      console.error('Error cropping the image', error);
      setUploadingImage(false);
    }
  };

  const rotateImage = (direction) => {
    setRotation(rotation + direction);
  };

  const handleClose = () => {
    if (imageSrc && imageSrc.startsWith('blob:')) {
      URL.revokeObjectURL(imageSrc);
    }
    setOpen(false);
    setEditingImageId(null);
    setImageSrc(null);
    resetCropState();
  };

  const handleZoomChange = (e, newZoom) => {
    setZoom(newZoom);
  };

  return {
    handleEditImage, // Export the edit handler
    component: (
      <div>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="upload-image-file"
          type="file"
          onChange={handleImageUpload}
        />
        <label htmlFor="upload-image-file">
          <Button variant="contained" component="span">
            Upload Image
          </Button>
        </label>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              maxHeight: '90vh',
              height: '90vh',
            },
          }}
        >
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              {editingImageId ? 'Edit Image' : 'Upload New Image'}
            </Typography>

            <div style={{
              position: 'relative',
              height: 'calc(100% - 150px)',
              minHeight: '300px',
              backgroundColor: '#333'
            }}>
              {imageSrc && (
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  aspect={aspectRatio}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                />
              )}
            </div>

            <div style={{ padding: '20px' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography style={{ display: 'flex', justifyContent: 'center', gap: '10px' }} variant="body2">Zoom</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={handleZoomChange}
                    aria-labelledby="Zoom"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography style={{ display: 'flex', justifyContent: 'center', gap: '10px' }} variant="body2">Rotation</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <IconButton onClick={() => rotateImage(-90)}>
                      <RotateLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => rotateImage(90)}>
                      <RotateRightIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={handleCropAndSave}
                  variant="contained"
                  color="primary"
                  disabled={uploadingImage}
                >
                  {uploadingImage ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  };
};

export default ImageUploaderEditor;
