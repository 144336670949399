import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid, Card, CardActionArea, Typography, Box,
  useTheme, useMediaQuery
} from '@mui/material';
import {
  Store, ListAlt, ShoppingCart, AccountBalance, Person,
  Draw, Inventory, CreditCard, AutoAwesomeMotion,
  LocalShipping, Business, Assignment
} from '@mui/icons-material';
import PageLayout from './PageLayout';
import TenantInfo from './TenantInfo';

const Splash = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    { name: 'Products', icon: Store, path: '/products', description: 'Manage your inventory', active: true },
    { name: 'Designs', icon: Draw, path: '/designs', description: 'Create and edit designs', active: true },
    { name: 'Lists', icon: ListAlt, path: '/lists', description: 'Organize your items', active: true },
    { name: 'Materials', icon: Inventory, path: '/materials', description: 'Manage raw materials', active: true },
    { name: 'Suppliers', icon: Business, path: '/suppliers', description: 'Manage suppliers', active: true },
    { name: 'Purchase Orders', icon: Assignment, path: '/purchase-orders', description: 'Track material orders', active: true },
    { name: 'Customers', icon: Person, path: '/customers', description: 'Manage your clients', active: false },
    { name: 'Sales', icon: ShoppingCart, path: '/sales', description: 'Track your revenue', active: false },
    { name: 'Accounts', icon: AccountBalance, path: '/accounts', description: 'Financial management', active: false },
  ];

  // const features = [
  //   { name: 'Inventory', icon: Inventory, path: '/inventory', description: 'Monitor stock levels', active: false },
  // ];

  const renderFeature = (feature) => {
    const FeatureIcon = feature.icon;
    return (
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',  // Higher shadow for hover
            transform: 'scale(1.03)',  // Slightly scale the card on hover
            backgroundColor: 'transparent',  // Ensure no background fill on hover
          },
          borderRadius: '16px',
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: feature.active ? 3 : 1,
          opacity: feature.active ? 1 : 0.6,
        }}
      >
        <CardActionArea
          component={feature.active ? Link : 'div'}
          to={feature.active ? feature.path : undefined}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
            backgroundColor: 'transparent',  // Ensure transparent background by default
            cursor: feature.active ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: 'transparent',  // Ensure no background color change on hover
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: feature.active ? 'primary.main' : 'action.disabled',
              borderRadius: '50%',
              p: 2,
              mb: 2,
            }}
          >
            <FeatureIcon sx={{ fontSize: 40, color: feature.active ? 'background.paper' : 'text.disabled' }} />
          </Box>
          <Typography variant="h6" component="div" align="center" gutterBottom color={feature.active ? 'text.primary' : 'text.disabled'}>
            {feature.name}
          </Typography>
          <Typography variant="body2" color={feature.active ? 'text.secondary' : 'text.disabled'} align="center">
            {feature.description}
          </Typography>
          {!feature.active && (
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                bgcolor: 'action.disabledBackground',
                color: 'text.disabled',
                borderRadius: '16px',
                px: 1,
                py: 0.5,
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}
            >
              Coming Soon
            </Box>
          )}
        </CardActionArea>
      </Card>
    );
  };

  return (
    <PageLayout>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            position: 'relative',
            display: 'inline-block',
            paddingBottom: '10px',
            '&::after': {
              content: '""',
              display: 'block',
              width: '100px',
              height: '3px',
              backgroundColor: '#58A4B0',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              transition: 'width 0.3s ease',  // Add transition for smooth effect
            },
            '&:hover::after': {
              width: '150px',  // Line grows on hover
            },
          }}
        >
          Welcome, <TenantInfo nameOnly />
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {renderFeature(feature)}
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  );
};

export default Splash;
