import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  InputAdornment,
  Divider
} from '@mui/material';
import PageLayout from '../PageLayout';
import ImageUploaderEditor from '../ImageUploaderEditor';
import api from '../../utils/api';

function MaterialForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!!id);
  const [error, setError] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    barcode: '',
    size: '',
    cost: '',
    url: '',
    supplier_id: '',
    reorder_point: ''
  });
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  // Get the image uploader component and handlers
  const imageEditor = ImageUploaderEditor({
    onImageEditComplete: handleImageUpload,
    onImageUpdate: handleImageUpdate
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [suppliersResponse] = await Promise.all([
          api.get('/materials/suppliers')
        ]);
        setSuppliers(suppliersResponse.data);

        if (id) {
          const materialResponse = await api.get(`/materials/${id}`);
          const material = materialResponse.data;
          setFormData({
            name: material.name,
            sku: material.sku || '',
            barcode: material.barcode || '',
            size: material.size || '',
            cost: material.cost || '',
            url: material.url || '',
            supplier_id: material.supplier_id || '',
            reorder_point: material.stock?.[0]?.reorder_point || ''
          });
          if (material.supplier_id) {
            setSelectedSupplier(suppliers.find(s => s.id === material.supplier_id));
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  async function handleImageUpload(imageBlob) {
    if (!id) return;

    try {
      const formData = new FormData();
      formData.append('image', imageBlob);
      await api.post(`/materials/${id}/images`, formData);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image. Please try again.');
    }
  }

  async function handleImageUpdate(imageId, imageBlob) {
    try {
      const formData = new FormData();
      formData.append('image', imageBlob);
      await api.put(`/materials/${id}/images/${imageId}`, formData);
    } catch (error) {
      console.error('Error updating image:', error);
      setError('Failed to update image. Please try again.');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submitData = {
        ...formData,
        supplier_id: selectedSupplier?.id,
        cost: parseFloat(formData.cost) || 0,
        reorder_point: parseInt(formData.reorder_point) || 0
      };

      if (id) {
        await api.put(`/materials/${id}`, submitData);
      } else {
        await api.post('/materials', submitData);
      }

      navigate('/materials');
    } catch (error) {
      console.error('Error saving material:', error);
      setError('Failed to save material. Please try again.');
    }
  };

  if (loading) return <PageLayout><Typography>Loading...</Typography></PageLayout>;

  return (
    <PageLayout>
      <Typography variant="h4" gutterBottom>
        {id ? 'Edit Material' : 'Create Material'}
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="SKU"
              name="sku"
              value={formData.sku}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Barcode"
              name="barcode"
              value={formData.barcode}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Size"
              name="size"
              value={formData.size}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cost"
              name="cost"
              type="number"
              value={formData.cost}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="URL"
              name="url"
              value={formData.url}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={suppliers}
              getOptionLabel={(option) => option.name}
              value={selectedSupplier}
              onChange={(event, newValue) => {
                setSelectedSupplier(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Reorder Point"
              name="reorder_point"
              type="number"
              value={formData.reorder_point}
              onChange={handleChange}
            />
          </Grid>

          {id && (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>Material Image</Typography>
              {imageEditor.component}
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {id ? 'Update' : 'Create'} Material
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default MaterialForm;
