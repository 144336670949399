// constants/index.js
export const SORT_OPTIONS = {
  NAME: 'product_name',
  PRICE: 'price',
  UPDATED: 'updated_at',
  CREATED: 'created_at'
};

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc'
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  FILTER_CATEGORY: 'filterCategory',
  FILTER_SUBCATEGORY: 'filterSubcategory',
  SEARCH_TERM: 'searchTerm',
  SORT_FIELD: 'sortField',
  SORT_DIRECTION: 'sortDirection',
  VIEW_MODE: 'productViewMode',
  SHOW_WITH_IMAGES: 'showOnlyWithImages'
};

export const DEFAULT_PAGINATION = {
  PAGE_SIZE: 25,
  INITIAL_PAGE: 0
};

// In constants/index.js
export const ERROR_MESSAGES = {
  GENERAL: 'An unexpected error occurred',
  UNAUTHORIZED: 'Your session has expired. Please log in again.',
  NETWORK: 'Network error. Please check your connection.',
  VALIDATION: 'Please check your input and try again.',
  FILE_DOWNLOAD: 'Failed to download file. Please try again.',
  FILE_UPLOAD: 'Error uploading file. Please try again.',
  FILE_DELETE: 'Error deleting file. Please try again.',
  FILE_LOAD: 'Error loading files. Please try again.',
};
