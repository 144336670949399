import React, { useState, useEffect } from 'react';
import api from '../utils/api';

const STORAGE_KEY = 'tenantInfo';

// Function to apply styles
const applyStyles = (styles) => {
  const styleElement = document.getElementById('tenant-styles') || document.createElement('style');
  styleElement.id = 'tenant-styles';
  styleElement.textContent = `
    :root {
      --primary-color: ${styles.primaryColor};
      --secondary-color: ${styles.secondaryColor};
      --background-color: ${styles.backgroundColor};
      --surface-color: ${styles.surfaceColor};
      --app-bar-color: ${styles.appBarColor};
      --drawer-background-color: ${styles.drawerBackgroundColor};
      --font-family: ${styles.fontFamily};
      --font-size: ${styles.fontSize}px;
    }
  `;
  document.head.appendChild(styleElement);
};

// Apply initial styles immediately
const storedInfo = localStorage.getItem(STORAGE_KEY);
if (storedInfo) {
  applyStyles(JSON.parse(storedInfo));
}

const TenantInfo = ({ nameOnly = false }) => {
  const [tenantInfo, setTenantInfo] = useState(() => {
    console.log('Initial load from localStorage:', storedInfo);
    return storedInfo ? JSON.parse(storedInfo) : null;
  });
  const [loading, setLoading] = useState(!tenantInfo);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTenantInfo = async () => {
      try {
        console.log('Fetching tenant info from server...');
        const response = await api.get('/config/getTenantInfo');
        let data;

        if (response.data) {
          data = response.data;
        } else if (response.json) {
          data = await response.json();
        } else {
          throw new Error('Unexpected response format');
        }

        console.log('Received data from server:', data);

        // Compare with stored data
        if (storedInfo !== JSON.stringify(data)) {
          console.log('Updating localStorage and state with new data');
          localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
          setTenantInfo(data);
          applyStyles(data);
        } else {
          console.log('Server data matches localStorage, no update needed');
        }

        setLoading(false);
      } catch (e) {
        console.error('Error fetching tenant info:', e);
        setError(e.message);
        setLoading(false);
      }
    };

    fetchTenantInfo();
  }, []);

  if (loading && !tenantInfo) return nameOnly ? null : <div>Loading...</div>;
  if (error) return nameOnly ? null : <div>Error: {error}</div>;
  if (!tenantInfo) return nameOnly ? null : <div>No info available</div>;

  if (nameOnly) {
    return tenantInfo.name;
  }

  return (
    <>
      {tenantInfo.logoUrl ? (
        <img
          src={tenantInfo.logoUrl}
          alt={`${tenantInfo.name} logo`}
          style={{ padding: '10px 0', maxWidth: '175px' }}
        />
      ) : (
        <p>{tenantInfo.name}</p>
      )}
    </>
  );
}

export default TenantInfo;
