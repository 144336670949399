// src/utils/errorHandler.js
import { ERROR_MESSAGES } from '../constants';

export const handleError = (error) => {
  if (error.response) {
    // Server error response
    if (error.response.status === 401) {
      window.dispatchEvent(new Event('unauthorized'));
      return ERROR_MESSAGES.UNAUTHORIZED;
    }
    return error.response.data?.message || ERROR_MESSAGES.GENERAL;
  }
  if (error.request) {
    // Network error
    return ERROR_MESSAGES.NETWORK;
  }
  return ERROR_MESSAGES.GENERAL;
};
