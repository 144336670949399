// services/listService.js
import api from '../utils/api';

export const listService = {
  getAll: async () => {
    const response = await api.get('/lists');
    return response.data;
  },

  getById: async (id) => {
    const response = await api.get(`/lists/${id}`);
    return response.data;
  },

  addProductsToList: async (listId, productIds) => {
    const response = await api.post(`/lists/${listId}/products`, { productIds });
    return response.data;
  },

  create: async (data) => {
    const response = await api.post('/lists', data);
    return response.data;
  },

  update: async (id, data) => {
    const response = await api.put(`/lists/${id}`, data);
    return response.data;
  },

  delete: async (id) => {
    await api.delete(`/lists/${id}`);
  }
};
