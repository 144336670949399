import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';

const TermsAndConditionsDialog = ({ open, onClose, onAccept }) => {
  console.log("TermsAndConditionsDialog rendered, open:", open);
  const [canAccept, setCanAccept] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    console.log("Effect running, open:", open);

    const checkScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        console.log("Scroll event - scrollTop:", scrollTop, "scrollHeight:", scrollHeight, "clientHeight:", clientHeight);
        const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1;
        console.log("Is at bottom:", isAtBottom);
        setCanAccept(isAtBottom);
      } else {
        console.log("contentRef is null during scroll check");
      }
    };

    if (open) {
      console.log("Dialog is open, setting up scroll listener");
      setTimeout(() => {
        if (contentRef.current) {
          console.log("Adding scroll event listener");
          contentRef.current.addEventListener('scroll', checkScroll);
          // Force a layout recalculation
          contentRef.current.style.display = 'none';
          void contentRef.current.offsetHeight; // Trigger reflow
          contentRef.current.style.display = '';
          checkScroll(); // Check initial scroll position
        } else {
          console.log("contentRef is still null after timeout");
        }
      }, 100);
    }

    return () => {
      if (contentRef.current) {
        console.log("Removing scroll event listener");
        contentRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [open]);

  useEffect(() => {
    console.log("canAccept state changed:", canAccept);
  }, [canAccept]);

  const handleAccept = () => {
    console.log("Accept button clicked, canAccept:", canAccept);
    if (canAccept) {
      onAccept();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent dividers ref={contentRef} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <Box sx={{ p: 2 }}>
          <section>
            <Typography variant="h6" gutterBottom>1. Acceptance of Terms</Typography>
            <Typography paragraph>
              By accessing or using our application, you agree to be bound by these Terms and Conditions and all applicable laws and regulations. If you do not agree with any part of these terms, you are prohibited from using or accessing this application.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>2. Use License</Typography>
            <Typography paragraph>
              We grant you a limited, non-exclusive, non-transferable, and revocable license to use our application for personal or business purposes, subject to these Terms and Conditions. You may not:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Modify or copy the materials" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Use the materials for any commercial purpose or for any public display" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Attempt to reverse engineer any software contained in the application" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Remove any copyright or other proprietary notations from the materials" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Transfer the materials to another person or 'mirror' the materials on any other server" />
              </ListItem>
            </List>
            <Typography paragraph>
              This license shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>3. Intellectual Property</Typography>
            <Typography paragraph>
              All content, features, and functionality of our application, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, are the exclusive property of our company or our content suppliers and protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>4. User Responsibilities</Typography>
            <Typography paragraph>
              You are responsible for:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Maintaining the confidentiality of your account and password" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Restricting access to your computer or mobile device" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Assuming responsibility for all activities that occur under your account or password" />
              </ListItem>
            </List>
            <Typography paragraph>
              We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our sole discretion.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>5. User-Generated Content</Typography>
            <Typography paragraph>
              Any content you upload to our application will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you grant us a limited license to use, store, copy, reproduce, and distribute such content.
            </Typography>
            <Typography paragraph>
              You represent and warrant that you own or control all rights to the content you post, and that the content does not violate these Terms or any applicable law.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>6. Prohibited Uses</Typography>
            <Typography paragraph>
              You may use our application only for lawful purposes and in accordance with these Terms. You agree not to use our application:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="In any way that violates any applicable federal, state, local, or international law or regulation" />
              </ListItem>
              <ListItem>
                <ListItemText primary="To transmit, or procure the sending of, any advertising or promotional material, including any 'junk mail,' 'chain letter,' 'spam,' or any other similar solicitation" />
              </ListItem>
              <ListItem>
                <ListItemText primary="To impersonate or attempt to impersonate the company, a company employee, another user, or any other person or entity" />
              </ListItem>
              <ListItem>
                <ListItemText primary="To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the application, or which may harm the company or users of the application" />
              </ListItem>
            </List>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>7. Limitation of Liability</Typography>
            <Typography paragraph>
              In no event shall our company, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Your access to or use of or inability to access or use the application" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Any conduct or content of any third party on the application" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Any content obtained from the application" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Unauthorized access, use or alteration of your transmissions or content" />
              </ListItem>
            </List>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>8. Disclaimer</Typography>
            <Typography paragraph>
              Our application and its contents are provided on an "as is" and "as available" basis without any warranties of any kind. We disclaim all warranties, whether express or implied, relating to the application and its contents, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>9. Termination</Typography>
            <Typography paragraph>
              We may terminate or suspend your access to our application immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>10. Governing Law</Typography>
            <Typography paragraph>
              These Terms shall be governed and construed in accordance with the laws of South Carolina, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>11. Changes to Terms</Typography>
            <Typography paragraph>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our application after those revisions become effective, you agree to be bound by the revised terms.
            </Typography>
          </section>

          <section>
            <Typography variant="h6" gutterBottom>12. Contact Us</Typography>
            <Typography paragraph>
              If you have any questions about these Terms, please contact us at info@crafthub360.com.
            </Typography>
          </section>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            {!canAccept ? "Please scroll to the bottom to accept" : "You may now accept the terms"}
          </Typography>
          <Box>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleAccept}
              disabled={!canAccept}
              color="primary"
              variant="contained"
              endIcon={!canAccept && <CircularProgress size={16} />}
            >
              I Accept
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
