// utils/api.js
import axios from 'axios';
import { getMainUrl, getApiUrl } from './urlHelpers';

const getBaseUrl = () => {
  const currentUrl = new URL(window.location.href);
  const mainUrl = new URL(getMainUrl());

  if (currentUrl.hostname === mainUrl.hostname) {
    return getApiUrl();
  }
  return getApiUrl();
};

const api = axios.create({
  baseURL: getBaseUrl(),
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
